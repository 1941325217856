const appName = '线索培育'
const routerPath = 'clueIncubation'
const routes = [
  {
    path: '/clue-incubation',
    name: 'clueIncubation',
    component: () => import('@/modules/clue-incubation/index.vue'),
    meta: {
      title: '线索培育',
      appName,
      isAppIndex: true,
      routerPath
    },
  },
  {
    path: '/clue-incubation/create',
    name: 'clueIncubationCreate',
    component: () => import('@/modules/clue-incubation/create/index.vue'),
    meta: {
      title: '新建线索',
      appName,
      isAppIndex: false,
      routerPath
    }
  },
  {
    path: '/clue-incubation/details',
    name: 'clueIncubationDetails',
    component: () => import('@/modules/clue-incubation/details/index.vue'),
    meta: {
      title: '线索详情',
      appName,
      isAppIndex: false,
      routerPath
    }
  },
  {
    path: '/clue-incubation/follow',
    name: 'clueIncubationFollow',
    component: () => import('@/modules/clue-incubation/follow/index.vue'),
    meta: {
      title: '线索跟进',
      appName,
      isAppIndex: false,
      routerPath
    }
  },
  {
    path: '/clue-incubation/assigned',
    name: 'ClueIncubationAssigned',
    component: () => import('@/modules/clue-incubation/details/assigned.vue'),
    meta: {
      title: '改派门店',
      appName,
      isAppIndex: false,
      routerPath
    }
  },
  {
    path: '/clue-incubation/edit',
    name: 'clueIncubationEdit',
    component: () => import('@/modules/clue-incubation/edit/index.vue'),
    meta: {
      routerPath
    },
    beforeEnter(to, from, next) {
      to.meta.appName = appName
      const { operate } = to.query
      if (operate == 'edit') {
        to.meta.title = '线索编辑'
      } else if (operate == 'create') {
        to.meta.title = '完善客户信息'
      } else {
        to.meta.title = '线索详情'
      }
      next()
    },
  },
]

export default routes
