const appName = '库存查询'
const routerPath = 'inventoryQuery'

const routes = [
  {
    path: '/inventory-query',
    name: 'inventoryQuery',
    redirect: '/stock', // 新需求新页面
    component: () => import('@/modules/inventoryQuery/index'),
    meta: {
      title: '库存查询',
      appName,
      isAppIndex: true,
      routerPath
    }
  }
]

export default routes
