<script>
import dragDialog from '@/utils/directive/el-dragDialog'

export default {
  name: 'BaseDialog',
  directives: {
    drag: dragDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    destroyOnClose: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '标题',
    },
    width: {
      type: String,
      default: '50%',
    },
    dragable: {
      type: Boolean,
      default: true,
    },
    top: {
      type: String,
      default: '10vh',
    },
    modal: {
      type: Boolean,
      default: true,
    },
    lockScroll: {
      type: Boolean,
      default: true,
    },
    customClass: {
      type: String,
    },
    maskClosable: {
      type: Boolean,
      default: false,
    },
    footerHeight: {
      type: [Number, String],
      default: 53,
    },
    containerStyle: {
      type: Object,
      default: () => ({}),
    },
    closeOnPressEscape: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isVisible: this.visible,
      fullscreen: false,
    }
  },
  computed: {
    isShowDialog() {
      return this.destroyOnClose ? this.isVisible : true
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.isVisible = val
      } else {
        setTimeout(() => {
          this.isVisible = false
          this.fullscreen = false
        }, 300)
      }
    },
  },
  methods: {
    close() {
      this.$emit('update:visible', false)
    },
    handleClick(e) {
      e.stopPropagation()
      this.fullscreen = !this.fullscreen
      // 可拖拽 & 全屏状态 重置 left/top
      if (this.dragable && this.fullscreen) {
        const $dragDom = this.$refs['dialog'].$el.querySelector('.el-dialog')
        $dragDom.style.left = 0
        $dragDom.style.top = 0
      }
      this.$emit('viewportChange', this.fullscreen ? 'fullscreen' : 'default')
    },
    createStyles(slots) {
      const hdHeight = '76px'
      const ftHeight = Object.keys(slots).includes('footer') ? `${this.footerHeight}px` : '0px'
      const dialogBodyHeight = this.fullscreen
        ? {
          height: `calc(100vh - ${ftHeight} - ${hdHeight})`,
        }
        : {
          maxHeight: `calc(100vh - ${this.top} - ${this.top} - ${ftHeight} - ${hdHeight})`,
        }
      return {
        overflowY: 'auto',
        minHeight: '150px',
        ...dialogBodyHeight,
      }
    },
  },
  render() {
    const { fullscreen, dragable, closable, maskClosable, containerStyle, closeOnPressEscape, $props, $attrs, $listeners, $slots } = this
    const wrapProps = {
      ref: 'dialog',
      props: {
        ...$props,
        appendToBody: true,
        showClose: closable,
        closeOnClickModal: maskClosable,
        fullscreen,
        beforeClose: this.close,
        destroyOnClose: false,
        closeOnPressEscape,
      },
      attrs: { ...$attrs },
      on: { ...$listeners },
      directives: dragable ? [{ name: 'drag' }] : null,
    }
    return this.isShowDialog ? (
      <el-dialog class="base-dialog" {...wrapProps}>
        {/* <span key="fullscreen" class="fullscreen-btn" onClick={this.handleClick}>
          <i class="el-icon-full-screen" />
        </span>*/}
        {Object.keys($slots).map((name) => (
          <section key={name} slot={name} style={name === 'default' ? this.createStyles($slots) : null}>
            <div class="container" style={containerStyle}>
              {$slots[name]}
            </div>
          </section>
        ))}
      </el-dialog>
    ) : null
  },
}
</script>

<style lang="less" scoped>
.base-dialog {
  overflow: hidden;
  ::v-deep .el-dialog {
    margin: 0 auto;
    .el-dialog__header {
      height: 56px;
      line-height: 56px;
      padding: 0 15px;
      box-sizing: border-box;
      border-bottom: 1px solid #d9d9d9;
      .el-dialog__title {
        font-size: 16px;
        color: rgba(#000, 0.85);
        line-height: inherit;
        font-weight: 500;
      }
      .el-dialog__headerbtn {
        font-size: 18px;
        line-height: 1.075;
        top: 19px;
        right: 15px;
      }
    }
    .el-dialog__body {
      padding: 32px 24px;
      // padding: 20px;
      .fullscreen-btn {
        position: absolute;
        right: 36px;
        top: 16px;
        padding: 0 5px;
        cursor: pointer;
        &:hover {
          color: #d9d9d9;
        }
      }
      .container {
        height: 100%;
        padding: 0;
      }
    }
    .el-dialog__footer {
      padding: 10px 24px;
      border-top: 1px solid #d9d9d9;
    }
  }
}
</style>
