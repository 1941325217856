export default {
  '未在店内查询到该客户': 'Customer not found in-store',
  '立即创建新线索': 'Create new lead immediately',
  '正在跟进该客户，你无法发起线下下单': 'You cannot place an offline order as you are currently following up with this customer',
  '自然进店': 'Natural store entry',
  '门店新建': 'Store newly built',
  '新建成功': 'Successfully created',
  '请输入电话号码完整搜索 / 或客户姓名': 'Please enter the complete phone number for searching / or customer name',
  '共': 'Total',
  '条数据': 'data entries',
  '线上签署': 'Online signature',
  '线下签署': 'Offline signature',
  '请及时代客户锁单': 'Please lock the order for the customer in time',
  '请联系客户及时支付定金': 'Please contact the customer to pay the deposit on time',
  '确认提交审核？': 'Confirm submission for review?',
  '取消申请后，所有申请数据将被删除，确认取消 ？': 'After cancellation, all application data will be deleted. Confirm cancellation?',
  '提交成功': 'Submission successful',
  '确认审核通过？': 'Confirm approval of the review?',
  '请填写驳回原因': 'Please fill the reason for rejection',
  '确认推送合同至客户签署 ？': 'Confirm sending the contract to the customer for signature?',
  '请上传文件': 'Please upload files',
  '线下下单用户': 'Offline ordering user',
  '创建时间': 'Creation time',
  '用户订单': 'User order',
  '订单号': 'Order number',
  '车系车型': 'Car model',
  '定金协议': 'Deposit agreement ',
  '购车合同': 'Car purchase contract ',
  '请拍摄并按顺序上传定金协议': 'Please take photos and upload the deposit agreement in sequence',
  '推送合同至客户签署': 'Push the contract to the customer for signature',
  '驳回原因': 'Reasons for rejection',
  '提交审核': 'Submit for review',
  '同意': 'Agree',
  '驳回': 'Reject',
  '取消申请': 'Cancel application',
  '关闭': 'Close',
  '去锁单': 'Go to lock the order',
  '查看订单': 'View order',
  '代客下单': 'Place order on behalf of customer',
  '线下下单': 'Offline ordering',
  '提示': 'Tip',
  '请输入': 'Please enter',
  '待审核': 'Pending review',
  '通过': 'Approved',
  '已审核': 'Approved',
  '已驳回': 'Reject',
  '申请状态': 'Application status',
  '销售顾问': 'Sales consultant',
  '该客户还未分配销售顾问': 'The customer has not yet been assigned a sales consultant',
  '立即领取': 'Receive immediately',
  '发起线下下单申请': 'Initiate offline ordering application',
  '请拍摄并按顺序上传购车合同': 'Please take photos and upload the car purchase contract in sequence',
  '请拍摄并按顺序上传个人信息处理告知同意书': 'Please take photos and upload personal information processing consent forms in order'
}
