
const routerPath = 'deliverManage'
import i18nFun from '../router_i18n'
const appName = i18nFun.交车明细
const routes = [
  {
    path: '/deliver-manage',
    name: 'deliverManage',
    component: () => import('@/modules/deliverManage/index.vue'),
    meta: {
      title: i18nFun.交车明细,
      appName,
      isAppIndex: true,
      routerPath,
    },
  },
  {
    path: '/deliver-detail',
    name: 'deliverDetail',
    component: () => import('@/modules/deliverManage/detail.vue'),
    meta: {
      title: i18nFun.交车详情,
      appName,
      isAppIndex: true,
      routerPath,
    },
  },
  {
    path: '/deliver-pdiEscalation',
    name: 'pdiEscalation',
    component: () => import('@/modules/deliverManage/pdiEscalation.vue'),
    meta: {
      routerPath,
    },
    beforeEnter(to, from, next) {
      to.meta.appName = appName
      const { operation } = to.query
      if (operation == 'edit') {
        to.meta.title = i18nFun.PDI上报
      } else {
        to.meta.title = i18nFun.PDI记录
      }
      next()
    },
  },
  {
    path: '/deliver-follow',
    name: 'deliverFollow',
    component: () => import('@/modules/deliverManage/deliverFollow.vue'),
    meta: {
      title: i18nFun.交车跟进,
      appName,
      isAppIndex: true,
      routerPath,
    },
  },
  {
    path: '/pickCarScheduling',
    name: 'pickCarScheduling',
    component: () => import('@/modules/deliverManage/pickCarScheduling.vue'),
    meta: {
      title: i18nFun.交车排程,
      appName,
      isAppIndex: true,
      routerPath,
    },
  },
  {
    path: '/deliver-data-upload',
    name: 'deliverDataUpload',
    component: () => import('@/modules/deliverManage/dataUpload.vue'),
    meta: {
      title: i18nFun.资料上传,
      appName,
      isAppIndex: true,
      routerPath,
    },
  },
  {
    path: '/deliver-record',
    name: 'deliverRecord',
    component: () => import('@/modules/deliverManage/deliverRecord.vue'),
    meta: {
      title: i18nFun.交车记录,
      appName,
      routerPath,
    },
  },
  {
    path: '/gift-record',
    name: 'giftRecord',
    component: () => import('@/modules/deliverManage/giftRecord.vue'),
    meta: {
      title: i18nFun.礼品记录,
      appName,
      routerPath,
    },
  },
  {
    path: '/loan-material',
    name: 'loanMaterial',
    component: () => import('@/modules/deliverManage/loan-material.vue'),
    meta: {
      title: i18nFun.金融贷后材料,
      appName,
      routerPath,
    },
  },
  {
    path: '/deliver-contract',
    name: 'deliverContract',
    component: () => import('@/modules/deliverManage/deliver-contract.vue'),
    meta: {
      title: i18nFun.交付确认书签署,
      appName,
      routerPath,
    },
  },
  {
    path: '/deliver-bill',
    name: 'deliverBill',
    component: () => import('@/modules/deliverManage/bill.vue'),
    meta: {
      title: i18nFun.开票信息,
      appName,
      routerPath,
    },
  },
  {
    path: '/vehicle-license',
    name: 'vehicleLicense',
    component: () => import('@/modules/deliverManage/vehicle-license.vue'),
    meta: {
      title: i18nFun.上牌指标登记,
      appName,
      routerPath,
    },
  },
  {
    path: '/temp-license',
    name: 'tempLicense',
    component: () => import('@/modules/deliverManage/temp-license.vue'),
    meta: {
      title: i18nFun.临牌信息登记,
      appName,
      routerPath,
    },
  },
  {
    path: '/official-license',
    name: 'officialLicense',
    component: () => import('@/modules/deliverManage/official-license.vue'),
    meta: {
      title: i18nFun.牌照信息登记,
      appName,
      routerPath,
    },
  },
  {
    path: '/purchase-insurance',
    name: 'purchaseInsurance',
    component: () => import('@/modules/deliverManage/purchase-insurance.vue'),
    meta: {
      title: i18nFun.品牌保险意愿确认,
      appName,
      routerPath,
    },
  },
  {
    path: '/invoiceDetail',
    name: 'InvoiceDetail',
    component: () => import('@/modules/deliverManage/invoiceDetail.vue'),
    meta: {
      title: i18nFun.发票详情,
      appName,
      routerPath,
    },
  },
  {
    path: '/vehicleReady',
    name: 'vehicleReady',
    component: () => import('@/modules/deliverManage/vehicleReady.vue'),
    meta: {
      title: i18nFun.车辆准备清单,
      appName,
      routerPath,
    },
  },
  {
    path: '/bookInspection',
    name: 'BookInspection',
    component: () => import('@/modules/deliverManage/bookInspection.vue'),
    meta: {
      title: i18nFun.预约到店验车,
      appName,
      routerPath,
    },
  },
  {
    path: '/protocolsUpload',
    name: 'ProtocolsUpload',
    component: () => import('@/modules/deliverManage/protocolsUpload.vue'),
    meta: {
      title: i18nFun.更多协议签署,
      appName,
      routerPath,
    },
  },
  {
    path: '/commonUpload',
    name: 'CommonUpload',
    component: () => import('@/modules/deliverManage/commonUpload.vue'),
    meta: {
      appName,
      routerPath,
    },
  },
  {
    path: '/remoteDelivery',
    name: 'RemoteDelivery',
    component: () => import('@/modules/deliverManage/remoteDelivery.vue'),
    meta: {
      title: i18nFun['送车上门申请'],
      appName,
      routerPath,
    },
  },
  {
    path: '/brandInsurance',
    name: 'brandInsurance',
    component: () => import('@/modules/deliverManage/brandInsurance.vue'),
    meta: {
      title: i18nFun.品牌保险推荐,
      appName,
      routerPath,
    },
  },
  {
    path: '/deliverLockDetails',
    name: 'deliverLockDetails',
    component: ()=>import('@/modules/deliverManage/deliverLock.vue'),
    meta: {
      title: '锁定详情'
    }
  }
]

export default routes
