const appName = '无效管理'
const routerPath = 'invalidManage'

const routes = [
  {
    path: '/invalid-manage',
    name: 'invalidManage',
    component: () => import('@/modules/invalidManage/index'),
    meta: {
      title: '无效管理',
      appName,
      isAppIndex: true,
      routerPath
    }
  },
  {
    path: '/clue-invalid-detail',
    name: 'clueInvalidDetail',
    component: () => import('@/modules/invalidManage/details.vue'),
    meta: {
      title: '线索详情',
      appName,
      isAppIndex: true,
      routerPath
    },
  },
]

export default routes
