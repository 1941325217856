import i18nFun from '../router_i18n'
const appName = i18nFun.素材库
const routerPath = 'material'

const routes = [
  {
    path: '/material',
    name: 'Material',
    component: () => import('@/modules/material'),
    meta: {
      title: i18nFun.素材库,
      appName,
      isAppIndex: true,
      routerPath
    }
  }
]

export default routes
