
// 不需要登录的页面配置
export const whileList = [
  '/test-drive-report',
  '/receiveGift',
  '/vSearch/articleDetail', // 素材分享
  '/vSearch/fileDetail', // 素材分享
  '/vSearch/graphicDetail', // 素材分享
  '/vSearch/videoDetail', // 素材分享
  '/test-drive-sign', // 试驾信息填写
  '/pdf-preview', // 提车催促书
]

export const getWhilePath = (path) => {
  const pathname = path || window.location.pathname
  try {
    return whileList.includes(pathname)
  } catch (error) {
    return
  }
}
