import i18nFun from '../router_i18n'
const appName = i18nFun.低满工单

const routes = [
  {
    path: '/lowFullWorkOrder',
    name: 'LowFullWorkOrder',
    component: () => import('@/modules/lowFullWorkOrder/index.vue'),
    meta: {
      title: i18nFun.低满工单,
      appName,
      isAppIndex: true,
      routerPath: 'lowFullWorkOrder'
    }
  },
  {
    path: '/lowFullWorkOrderFactory',
    name: 'LowFullWorkOrderFactory',
    component: () => import('@/modules/lowFullWorkOrder/index.vue'),
    meta: {
      title: i18nFun.低满工单,
      appName,
      isAppIndex: true,
      routerPath: 'lowFullWorkOrderFactory',
    }
  },
  {
    path: '/workOrderDetail',
    name: 'WorkOrderDetail',
    component: () => import('@/modules/lowFullWorkOrder/detail.vue'),
    meta: {
      title: i18nFun.低满工单,
      appName,
      isAppIndex: true,
      routerPath: 'lowFullWorkOrder'
    }
  }
]

export default routes
