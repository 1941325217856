import i18nFun from '../router_i18n'
const appName = i18nFun['线下下单']
const routerPath = 'offlineOrder'

const routes = [
  {
    path: '/offlineOrder',
    name: 'offlineOrder',
    component: () => import('@/modules/offlineOrder/index'),
    meta: {
      title: appName,
      appName,
      isAppIndex: true,
      routerPath
    }
  },
  {
    path: '/offlineOrder/detail',
    name: 'offlineOrderDetail',
    component: () => import('@/modules/offlineOrder/detail'),
    meta: {
      isAppIndex: true,
      appName,
      routerPath
    },
    beforeEnter(to, from, next) {
      const { titleType = '' } = to.query
      if (titleType == 'uploadContract') {
        to.meta.title = i18nFun['uploadContract']
      } else if (titleType == 'viewContract') {
        to.meta.title = i18nFun['viewContract']
      } else to.meta.title = appName
      next()
    },
  },
]

export default routes
