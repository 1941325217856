const appName = ''
const routerPath = 'externalH5'
import i18nFun from '../router_i18n'

const routes = [
  {
    path: '/test-drive-sign',
    name: 'testDriveSign',
    component: () => import('@/modules/external-h5/test-drive-sign.vue'),
    meta: {
      title: i18nFun.试乘试驾信息登记,
      appName,
      isAppIndex: true,
      routerPath
    }
  },
  {
    path: '/pdf-preview',
    name: 'pdfPreview',
    component: () => import('@/modules/external-h5/pdf-preview.vue'),
    meta: {
      title: '文件预览',
      appName,
      isAppIndex: true,
      routerPath
    }

  }
]

export default routes
