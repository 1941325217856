import i18nFun from '../router_i18n'
const appName = i18nFun['我的待办']
const routerPath = 'myTodo'
const routes = [
  {
    path: '/my-todo',
    name: 'myTodo',
    component: () => import('@/modules/myTodo/index'),
    meta: {
      title: i18nFun['我的待办'],
      appName,
      isAppIndex: true,
      routerPath
    },
  },
  {
    path: '/my-todo-portal',
    name: 'myTodoPortal',
    // component: () => import('@/modules/myTodo/portal'),
    component: () => import('@/modules/myTodo/chatTasks'),
    meta: {
      title: i18nFun['我的待办'],
      appName,
      isAppIndex: true,
      routerPath
    },
  },
  {
    path: '/todo-list',
    name: 'todoList',
    component: () => import('@/modules/myTodo/todoList'),
    meta: {
      title: i18nFun['待办任务'],
      routerPath: 'salesAssistant' // 销售助手
    },
  },
  {
    path: '/todo-group-chats',
    name: 'todoGroupChats',
    component: () => import('@/modules/myTodo/groupChats'),
    props: route => ({ taskCode: route.query.taskCode }),
    meta: {
      title: i18nFun['建群任务'],
      routerPath
    },
  },
  {
    path: '/todo-chat-tasks',
    name: 'todoChatTasks',
    component: () => import('@/modules/myTodo/chatTasks'),
    meta: {
      title: i18nFun['企微任务'],
      routerPath: 'chatTasks'
    },
  },
]

export default routes
