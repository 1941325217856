<template>
  <div :style="style" class="common-index-bar">
    <div class="index-bar-content">
      <van-index-bar ref="indexBar" :index-list="indexList">
        <div v-for="index of indexList" :key="index">
          <van-index-anchor :index="index"> </van-index-anchor>
          <ul v-if="isSelection" class="ul-index">
            <li
              v-for="call in content[index]"
              :key="call[nodeKey]"
              :value="call.value"
              @click="onClick(call)"
            >
              <template v-if="isSelection">
                <template v-if="multiple">
                  <svg
                    v-if="activeList.includes(call[nodeKey])"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-12"
                  >
                    <rect
                      y="0.951172"
                      width="18"
                      height="18"
                      rx="4"
                      fill="#3476FE"
                    />
                    <path
                      d="M13.8403 5.75892C14.0882 5.48664 14.5098 5.46684 14.7821 5.71468C15.0544 5.96253 15.0742 6.38417 14.8263 6.65645L8.0119 14.1426C7.7533 14.4266 7.30879 14.434 7.04096 14.1586L3.18875 10.1975C2.93204 9.93359 2.93793 9.51151 3.20188 9.25482C3.46583 8.99811 3.8879 9.004 4.1446 9.26795L7.50283 12.7211L13.8403 5.75892Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    v-else
                    class="mr-12"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="17"
                      height="17"
                      rx="3.5"
                      fill="white"
                    />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="17"
                      height="17"
                      rx="3.5"
                      stroke="#A9AAAD"
                    />
                  </svg>
                </template>
                <template v-else>
                  <van-icon
                    v-if="activeList.includes(call[nodeKey])"
                    class="mr-12"
                    name="passed"
                    color="rgb(52, 118, 254)"
                  />
                  <van-icon
                    v-else
                    name="circle"
                    class="mr-12"
                    color="rgb(217, 217, 217)"
                  />
                </template>
              </template>
              <img
                v-if="isPeople"
                class="avatar"
                referrer="no-referrer|origin|unsafe-url"
                :src="avatar(call)"
              />
              <div>
                <p class="title">
                  {{ call[titleKey] }}
                </p>
                <p v-if="call.phone" class="vacation">
                  {{ call.phone }}
                </p>
              </div>
              <p v-if="call.followdes" class="follow">{{ call.followdes }}</p>
            </li>
          </ul>
          <ul v-else class="ul-index">
            <li v-for="call in item" :key="call[nodeKey]">
              <img v-if="isPeople" class="avatar" :src="avatar(call)" />              
              <p class="title">{{ call[titleKey] }}</p>
            </li>
          </ul>
        </div>
      </van-index-bar>
    </div>
  </div>
</template>
<script>
import { isArray } from 'lodash'

export default {
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    min: {
      type: Number,
      default: () => 1,
    },
    max: {
      type: [Number, undefined],
      default: () => undefined,
    },
    indexKey: {
      type: String,
      default: () => 'index',
    },
    titleKey: {
      type: String,
      default: () => 'title',
    },
    isSelection: {
      // 是否可选择
      type: Boolean,
      default: () => false,
    },
    nodeKey: {
      //
      type: String,
      default: () => 'id',
    },
    height: {
      // 容器高度
      type: String,
      default: () => '',
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false,
    },
    isPeople: {
      // 是否为选择顾问
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectionMap: new Map(),
      changeTracker: 0,
      anchorIndex: '',
    }
  },
  computed: {
    indexList() {
      return Object.keys(this.content).sort()
    },
    style() {
      const obj = {
        'min-height': this.indexList.length * 18 + 'px',
      }
      if (this.height) {
        obj.height = this.height
      } else {
        obj['max-height'] = '500px'
      }
      return obj
    },
    activeList() {
      const changeTracker = this.changeTracker
      return Array.from(this.selectionMap.keys())
    },
  },
  watch: {
    indexList: {
      handler(val) {
        if (val.length) {
          this.$nextTick(() => {
            this.$refs.indexBar.scrollTo(val[0])
          })
        }
      },
      immediate: true,
    },
  },

  methods: {
    reset() {
      if (this.selectionMap.size) {
        this.selectionMap = new Map()
        this.changeTracker++
      }
    },
    getData() {
      return Array.from(this.selectionMap.values())
    },
    avatar({ userAvatar, gender }) {
      let url =
        'https://crm-file-1304630021.cos.ap-beijing.myqcloud.com/workbenchAppLogo/default.png'
      if (userAvatar) {
        url = userAvatar
      } else if (gender === 1) {
        url =
          'https://crm-file-1304630021.cos.ap-beijing.myqcloud.com/workbenchAppLogo/women.png'
      } else if (gender === 0) {
        url =
          'https://crm-file-1304630021.cos.ap-beijing.myqcloud.com/workbenchAppLogo/man.png'
      }
      return url
    },
    onClick(item) {
      let flag = false
      const key = item[this.nodeKey]
      if (this.selectionMap.has(key)) {
        this.selectionMap.delete(key) // 删除
        flag = true
      } else if (
        this.multiple &&
        (typeof this.max === 'number'
          ? this.selectionMap.size < this.max
          : true)
      ) {
        flag = true
        this.selectionMap.set(key, item)
      } else if (!this.multiple) {
        this.selectionMap = new Map([[key, item]])
        flag = true
      }
      if (flag) {
        this.changeTracker++
        this.$emit('change', Array.from(this.selectionMap.values()))
      }
    },

    // 对外暴露的方法
    CHECK_OFF_ITEM(tagValue) {
      tagValue = isArray(tagValue) ? tagValue[0] : tagValue
      console.log(
        'CHECK_OFF_ITEM COMMON',
        tagValue,
        this.selectionMap,
        this.selectionMap.has(tagValue)
      )
      // 如果本来就是取消状态，则不做任何操作
      if (!this.selectionMap.has(tagValue))
        return console.log('CHECK_OFF_ITEM, 本来就是取消状态')

      // 从content中找到对应的item
      const item = Object.values(this.content).find((item) =>
        item.find((call) => call[this.nodeKey] == tagValue)
      )
      console.log('CHECK_OFF_ITEM, item', item)

      // 如果item不存在，则不做任何操作
      if (!item) return console.log('CHECK_OFF_ITEM, item不存在')

      // 如果item存在，则触发点击事件
      this.onClick(item.find((call) => call[this.nodeKey] == tagValue))
    },
  },
}
</script>
<style lang='less' scoped>
.mr-12 {
  margin-right: 12px;
}
.common-index-bar {
  position: relative;
  display: flex;
  flex-direction: column;
  .index-bar-content {
    flex: 1;
    overflow: auto;
  }
  /deep/ .van-index-bar__sidebar {
    position: absolute;
    right: 4px;
  }
  /deep/.van-index-anchor--sticky {
    position: absolute;
    left: 0 !important;
    transform: translate3d(0px, 0px, 0px) !important;
  }
  /deep/ .van-index-anchor {
    color: rgba(52, 118, 254, 1);
    font-family: Montserrat;
    font-size: 16px;
    height: 52px;
    line-height: 52px;
    padding: 0;
  }
  /deep/ .van-index-bar__index--active {
    background: rgba(52, 118, 254, 1);
    color: #ffff;
    border-radius: 50%;
  }
  /deep/ .van-index-bar__index {
    padding: 3px;
    line-height: 1;
  }
  .ul-index {
    li {
      display: flex;
      align-items: center;
      height: 60px;
      border-bottom: 1px solid rgba(248, 248, 248, 1);
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 10px;
      }
      .title {
        height: 14px;
        color: rgba(0, 0, 0, 1);
        font-family: PingFang SC;
        font-size: 12px;
        line-height: 14px;
      }
      .vacation {
        height: 18px;
        line-height: 18px;
        border-radius: 4px;
        background: rgba(253, 243, 244, 1);
        margin-top: 8px;
        color: rgb(255, 86, 91);
        font-size: 12px;
        padding: 0 2px;
      }
      .follow {
        padding: 0 8px;
        border-radius: 4px;
        background: rgba(244, 244, 244, 1);
        height: 22px;
        line-height: 22px;
        color: rgba(50, 50, 50, 1);
        font-size: 10px;
        margin-left: 15px;
      }
    }
  }
}
</style>
