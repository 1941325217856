const appName = '话术库'
const routerPath = 'talkSkill'
const routes = [
  {
    path: '/talk-skill',
    name: 'talkSkill',
    component: () => import('@/modules/talk-skill/index'),
    props: { origin: 'app' },
    meta: {
      title: '话术库',
      appName,
      isAppIndex: true,
      routerPath,
    },
  },
  {
    path: '/talk-skill-details',
    name: 'talkSkillDetails',
    component: () => import('@/modules/talk-skill/details'),
    meta: {
      title: '话术详情',
      appName,
      routerPath,
    },
  },
  {
    path: '/talk-skill-edit',
    name: 'talkSkillEdit',
    component: () => import('@/modules/talk-skill/edit'),
    meta: {
      title: '话术编辑',
      appName,
      routerPath,
    },
  },
  {
    path: '/talk-skill-edit-content',
    name: 'talkSkillEditContent',
    component: () => import('@/modules/talk-skill/editContent'),
    meta: {
      title: '编辑发送话术',
      appName,
      routerPath,
    },
  },
  {
    path: '/talk-skill-custom-classify',
    name: 'talkSkillCustomClassify',
    component: () => import('@/modules/talk-skill/custom-classify'),
    meta: {
      title: '个人分类设置',
      appName,
      routerPath,
    },
  },
]

export default routes
