import i18nFun from '../router_i18n'
const appName = i18nFun.opportunityManagement
const routerPath = 'opportunityManage'

const routes = [
  {
    path: '/opportunityManage',
    name: 'OpportunityManage',
    component: () => import('@/modules/opportunity/index'),
    meta: {
      title: i18nFun.opportunityManagement,
      appName,
      isAppIndex: true,
      routerPath
    }
  },
]

export default routes
