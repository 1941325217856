export default {
  '车型':'车型',
  '车系':'车系',
  '车辆状态恢复后，客户可进行预约试驾操作，请确认是否恢复！':'车辆状态恢复后，客户可进行预约试驾操作，请确认是否恢复！',
  '恢复可用成功':'恢复可用成功',
  '恢复可用':'恢复可用',
  '可用车辆':'可用车辆',
  '不可用车辆':'不可用车辆',
  '查看排程': '查看排程',
  '外饰颜色：': '外饰颜色：',
  '内饰颜色：': '内饰颜色：',
  '录入日期：': '录入日期：',
  '车辆占用': '车辆占用',
  '车牌': '车牌',
  '预约时间': '预约时间',
  '预约详细信息': '预约详细信息',
  '占用原因': '占用原因',
  '请选择占用原因': '请选择占用原因',
  '占用时间': '占用时间',
  '请选择占用时间': '请选择占用时间',
  '占用说明': '占用说明',
  '请输入占用说明': '请输入占用说明',
  '返回': '返回',
  '提交': '提交',
  '至': '至',
  '请确认，结束时间要大于开始时间': '请确认，结束时间要大于开始时间',
  '报错了': '报错了',
  '车牌：': '车牌：',
  '试驾车占用轨迹': '试驾车占用轨迹',
  '月': '月',
  '试驾车占用': '试驾车占用',
}
