<template>
  <div class="empty-wrap">
    <div v-if="loading" class="loading">
      <van-loading color="#EED484" />
    </div>
    <van-empty v-else :image="image" :description="description" />
  </div>
</template>
<script>
export default {
  props: {
    description: {
      type: String,
      default: '暂无数据',
    },
    image: {
      type: String,
      default: () => require('@/images/empty.png'),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang='less' scoped>
.empty-wrap {
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 260px;
    height: 55px;
  }
  /deep/ .van-empty__image {
    pointer-events: none;
  }
}
</style>