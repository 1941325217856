import Vue from 'vue'
import VueRouter from 'vue-router'
import agentAllJson from './agentAll.json'
import store from '@/store'
import vendorServices from '@/services/vendorServices'
import PAGES from '@/modules/home/pages'
import { getWhilePath } from '@/router/whiteList'
import baseDataServices from '@/services/baseDataServices'
let allRoute = []
const routeFiles = require.context('@/router/modules', false, /\.js$/)
routeFiles.keys().forEach(key => {
  allRoute = allRoute.concat(routeFiles(key).default)
})

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/modules/home')
    },
    ...allRoute,
  ]
})

router.beforeEach(async (to, from, next) => {
  const disableBackPath = PAGES.map(item => { return item.path })
  if (disableBackPath.includes(to.path)) {
    to.meta.disableBack = true
  }
  const { name, query, params } = to
  if (!name) {
    console.error(`${to.path} route need to provide \`name\` field.`)
  }
  // 如果是多店 消息中心会带一个当前的店铺信息（shopIdFromMessage）获取后存在全局
  if (query.shopIdFromMessage) {
    store.commit('app/setSelectedShopId', query.shopIdFromMessage)
  }
  const routerPath = to?.meta.routerPath
  const envName = process.env.VUE_APP_ENV || 'uat'
  const lastAgentId = store.getters.globalQuery?.agentId
  const agentId = agentAllJson[envName][routerPath]
  if (agentId && agentId !== lastAgentId) {
    await baseDataServices.getQwResourceNew({ agentId })
  }
  agentId && store.commit('app/setGloblaQuery', {
    ...store.getters.globalQuery,
    agentId
  })
  const globalQuery = store.getters.globalQuery
  let hasGlobalQuery = true
  const toQuery = { ...query }
  const isWhileList = getWhilePath(to.path)
  !isWhileList && Object.entries(globalQuery).forEach(([key, value]) => {
    if (typeof query[key] === 'undefined') {
      hasGlobalQuery = false
      toQuery[key] = value
    }
  })
  delete toQuery.replace
  delete toQuery.token
  next(
    hasGlobalQuery
      ? undefined
      : {
        replace: query.replace && JSON.parse(query.replace),
        name,
        query: toQuery,
        params
      }
  )
})

router.afterEach(async to => {
  const { meta: { title = '销售助手' } = {} } = to
  window.document.title = title
  const isWhileList = getWhilePath(to.path)
  if (!['clueIncubation'].includes(to.name) && !isWhileList) {
    console.info('router afterEach wxAgentConfig', to)
    vendorServices.wxAgentConfig()
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject) return originalPush.call(this, location, resolve, reject)
  return originalPush.call(this, location).catch((e) => { })
}
export default router
