<template>
  <div id="app" :class="!judgePc ? 'appPc' : ''">
    <!-- 水印 -->
    <WaterMark/>
    <keep-alive :include="keepAliveComponents">
      <router-view></router-view>
    </keep-alive>
    <div v-if="showTabBar" class="common-footer" style="padding-left: 0;padding-right: 0;" >
      <div v-for="(item, index) in barInfo" :key="index" :class="['tabBar', $route.path == item.path ? 'tabBar_active' : '']" @click="jump(item)">
        <img :src="$route.path == item.path ? item.iconActive : item.icon">
        <div>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { mapState, mapGetters } from 'vuex'
import { getWxEntry } from '@/utils'
import homeIcon from '@/images/home_icon.png'
import userIcon from '@/images/user_icon.png'
import stagingIcon from '@/images/staging_icon.png'
import homeIconActive from '@/images/home_icon_active.png'
import userIconActive from '@/images/user_icon_active.png'
import stagingIconActive from '@/images/staging_icon_active.png'
import WaterMark from '@/components/water-mark/index.vue'
export default {
  name: 'App',
  components:{ WaterMark },
  data() {
    return {
      pageEntry: '',
      isChatWindow: false,
      homeIcon,
      userIcon,
      stagingIcon,
      homeIconActive,
      userIconActive,
      stagingIconActive,
      barInfo: [
        { name: this.$t('首页'), icon: homeIcon, iconActive: homeIconActive, path: '/salesAssistant' },
        { name: this.$t('用户'), icon: userIcon, iconActive: userIconActive, path: '/clue-list' },
        { name: this.$t('工作台'), icon: stagingIcon, iconActive: stagingIconActive, path: '/salesAssistant/staging' },
      ]
    }
  },
  computed: {
    ...mapGetters([ 'selectedShopId']),
    ...mapState({
      keepAliveComponents: (state) => state.app.keepAliveComponents,
    }),
    judgePc() {
      return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
    },
    showTabBar() {
      const { path, query } = this.$route
      const { fromAssistant=false } = query
      return path == '/salesAssistant' || (['/clue-list','/salesAssistant/staging'].includes(path) && fromAssistant)
    },
  },
  watch: {
    // 监听到店铺信息发生变化 
    selectedShopId: {
      handler(val, oldVal) {
        // 重新获取detail 获取隐私号配置
        if (val && val !== oldVal) {
          this.getDetailAgain()
          store.commit('app/deleteAllKeepAlive', [])
          this.getHasMappingAgain(val)
        }
      },
      deep: true
    }
  },
  async mounted() {
    // this.pageEntry = await getWxEntry()
    // if (['contact_profile','single_chat_tools','group_chat_tools','chat_attachment',].includes(this.pageEntry)) {
    //   this.isChatWindow = true
    // }
  },
  methods: {
    async jump({ path }) {
      this.$router.replace({
        path,
        query: {
          fromAssistant: true,
          replace: true
        }
      })
    },
  }
 
}
</script>
<!-- the below example thatApp.vue have i18n custom block: -->
<i18n>{}</i18n>
<style lang="less">
#app{
  position: relative;
  .tabBar{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    flex: 1;
    &.tabBar_active{
      color: #B9921A;
    }
    .home{
      color: @yellow-text;
    }
    img{
      width: 20px;
      height: 20px;
      margin-bottom: 2px;
    }
  }
}
</style>
