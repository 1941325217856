// import { getMaterial, getLinkMaterial, getMaterialCategory } from '@/services/materialServices'
export default {
  namespaced: true,
  state: {
    categoryList: [],
    materialList: [],
    materialCount: 0,
    queryParams: {
      groupList: [],
      pageIndex: 1,
      pageSize: 10,
      title: '',
      groupCodes: ['OPA_DIS_ROOT'], // 厂端
      type: 1,
    },
    isLoading: false,
    isFinished: false
  },
  mutations: {
    setCategoryList(state, categoryList) {
      state.categoryList = categoryList
    },

    setMaterialList(state, materialList) {
      state.materialList = materialList
    },

    setMaterialCount(state, count) {
      state.materialCount = count
    },

    setQueryParams(state, params) {
      state.queryParams = { ...state.queryParams, ...params }
    },

    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },

    setIsFinished(state, isFinished) {
      state.isFinished = isFinished
    }
  },
  // actions: {
  //   // 获取素材列表
  //   async fetchMaterialList({ commit, state }) {
  //     try {
  //       commit('setIsLoading', true)
  //       const res = await getMaterial(state.queryParams)
  //       commit('setIsLoading', false)
  //       if (res.code === 0) {
  //         const list = [...state.materialList, ...res.data.dataList]
  //         commit('setMaterialList', list)
  //         commit('setMaterialCount', res.data.page.totalCount)
  //         if (list.length === res.data.page.totalCount) {
  //           commit('setIsFinished', true)
  //         }
  //         else {
  //           commit('setIsFinished', false)
  //         }
  //       }
  //     }
  //     catch (e) {
  //       commit('setIsLoading', false)
  //     }
  //   },

  //   // 获取素材分组列表
  //   async fetchMaterialCategoryList({ commit }, params = {}) {
  //     const res = await getMaterialCategory(params)
  //     if (res.code === 0) {
  //       commit('setCategoryList', res.data)
  //     }
  //   },

  //   async fetchLinkMaterialList({ commit, state }) {
  //     try {
  //       commit('setIsLoading', true)
  //       const res = await getLinkMaterial(state.queryParams)
  //       commit('setIsLoading', false)
  //       if (res.code === 0) {
  //         const list = [...state.materialList, ...res.data.dataList]
  //         commit('setMaterialList', list)
  //         commit('setMaterialCount', res.data.page.totalCount)
  //         if (list.length === res.data.page.totalCount) {
  //           commit('setIsFinished', true)
  //         }
  //         else {
  //           commit('setIsFinished', false)
  //         }
  //       }
  //     }
  //     catch (e) {
  //       commit('setIsLoading', false)
  //     }
  //   }
  // }
}
