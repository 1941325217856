import i18nFun from '../router_i18n'
const appName = i18nFun['预约明细']
const routerPath = 'appointment'
const routes = [
  {
    path: '/appointment',
    name: 'Appointment',
    component: () => import('@/modules/appointment/index'),
    meta: {
      title: i18nFun['预约明细'],
      appName,
      isAppIndex: true,
      routerPath
    },
  },
  {
    path: '/appointment-drive',
    name: 'appointmentDrive',
    component: () => import('@/modules/appointment/testDrive'),
    meta: {
      title: i18nFun['预约试驾'],
      appName,
      isAppIndex: true,
      routerPath
    },
  },
  {
    path: '/appointment-drive-detail',
    name: 'appointmentTestDriveDetail',
    component: () => import('@/modules/appointment/testDriveDetail'),
    meta: {
      title: i18nFun['预约明细'],
      appName,
      isAppIndex: true,
      routerPath
    },
  },
  {
    path: '/appointment-detail',
    name: 'appointmentDetail',
    component: () => import('@/modules/appointment/detail'),
    meta: {
      title: i18nFun['预约详情'],
      appName,
      isAppIndex: true,
      routerPath
    },
  },
  {
    path: '/appointment-update',
    name: 'appointmentUpdate',
    component: () => import('@/modules/appointment/update'),
    meta: {
      title: i18nFun['试驾车排程'],
      appName,
      isAppIndex: true,
      routerPath
    },
  },
  {
    path: '/appointment-shop',
    name: 'appointmentShop',
    component: () => import('@/modules/appointmentShop/index'),
    meta: {
      title: i18nFun['预约到店'],
      appName,
      isAppIndex: true,
      routerPath
    },
  }, {
    path: '/appointment-demo',
    name: 'appointmentDemo',
    component: () => import('@/modules/appointment/demo'),
    meta: {
      title: i18nFun['试驾车排程'],
      appName,
      isAppIndex: true,
      routerPath
    },
  }
]

export default routes
