const appName = '线索战败管理'
const routerPath = 'clueDefeat'
const routes = [
  {
    path: '/clue-defeat',
    name: 'clueDefeat',
    component: () => import('@/modules/clueDefeat/index'),
    meta: {
      title: '线索战败管理',
      appName,
      isAppIndex: true,
      routerPath
    },
  },
  {
    path: '/clue-defeat-detail',
    name: 'clueDefeatDetail',
    component: () => import('@/modules/clueDefeat/details.vue'),
    meta: {
      title: '线索详情',
      appName,
      isAppIndex: true,
      routerPath
    },
  },
]

export default routes
