export default [{
  path: '/vSearch',
  name: 'vSearch',
  component: () => import(/* webpackChunkName: 'vSearch' */ '@/modules/vSearch'),
  redirect: '/vSearch/home',
  meta: { title: '知识库搜索' },
  children: [
    {
      path: '/vSearch/home',
      name: 'vSearchHome',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/modules/vSearch/vSearchHome'),
      meta: { title: 'VSearch首页', unGetUser: true }
    },
    {
      path: '/vSearch/articleDetail',
      name: 'articleDetail',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/modules/vSearch/articleDetail'),
      meta: { title: '文章详情', unGetUser: true }
    },
    {
      path: '/vSearch/fileDetail',
      name: 'fileDetail',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/modules/vSearch/fileDetail'),
      meta: { title: '文件详情', unGetUser: true }
    },
    {
      path: '/vSearch/graphicDetail',
      name: 'graphicDetail',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/modules/vSearch/graphicDetail'),
      meta: { title: '图片详情', unGetUser: true }
    },
    {
      path: '/vSearch/videoDetail',
      name: 'videoDetail',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/modules/vSearch/videoDetail'),
      meta: { title: '多媒体详情', unGetUser: true }
    }
  ]
}]
