// 用于获取基础数据的服务
import request from '@/utils/request'
import SERVER from './server'
import { Toast } from 'vant'
import store from '@/store'
import { thenResolve } from '@/utils/index'

const services = {
  /**
   * 查询所有的车系车型
  */
  async getAllSeriesModels() {
    return await request.get(`${SERVER.BASE_DATA_SERVICE}/api/v1/modelSales/listAllSeriesModels`).then(thenResolve)
  },
  /**
   * 订单编辑的时候查询车系车型 外部接口
  */
  async seriesModelsForEditOrder() {
    return await request.get(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/queryModels`).then(thenResolve)
  },
  /**
   * 查询车系
  */
  async getSeriesList() {
    return await request.get(`${SERVER.BASE_DATA_SERVICE}/api/v1/modelSales/series`).then(thenResolve)
  },
  /**
   * 查询车型
  */
  async getModelList(params) {
    return await request.get(`${SERVER.BASE_DATA_SERVICE}/api/v1/modelSales/models`, { params }).then(thenResolve)
  },
  /**
   * 查询所有渠道来源
  */
  async getAllChannelList(params) {
    return await request.get(`${SERVER.LEAD_SERVICE}/api/v1/channel/tree`, { params }).then(thenResolve)
  },
  /**
   * 分级查询所有渠道数据
  */
  async getChannelListById(params) {
    return await request.get(`${SERVER.LEAD_SERVICE}/api/v1/channel/child`, { params }).then(thenResolve)
  },
  /**
   * 根据角色查询人员信息
  */
  async getStaffList(params) {
    return await request.get(`${SERVER.ADMIN_SERVICE}/api/v1/employee/qw/listEmpByBiz`, { params }).then(thenResolve)
  },
  /**
   * 通过业务类型查询员工列表,返回首字母map
  */
  async getListEmpToMap(params) {
    return await request.get(`${SERVER.ADMIN_SERVICE}/api/v1/employee/qw/listEmpToMap`, { params }).then(thenResolve)
  },
  // 获取试驾车列表
  async getTestDriveList(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/car/list`, params).then(thenResolve)
  },
  // 获取无效原因
  async getInvalidReasonList(params) {
    return await request.post(`${SERVER.BASE_DATA_SERVICE}/api/v1/invalidClue/list`, { params }).then(thenResolve)
  },
  // 战败原因
  async defeatClueTree(params) {
    return await request.post(`${SERVER.BASE_DATA_SERVICE}/api/v1/defeatClue/tree`, { params }).then(thenResolve)
  },
  // 战败原因 新的
  async defeatClueTreeNew(params) {
    return await request.get(`${SERVER.BASE_DATA_SERVICE}/api/v1/defeatClue/defeatClueTree`, { params }).then(thenResolve)
  },
  // 获取战败原因
  async getDefeatReasonList(params) {
    return await request.post(`${SERVER.BASE_DATA_SERVICE}/api/v1/defeatClue/list`, { params }).then(thenResolve)
  },
  /**
   * 外部联系人列表
  */
  async getExternalList(params) {
    return await request.get(`${SERVER.QW_SERVICE}/api/v1/empExternal/list`, { params }).then(thenResolve)
  },
  /**
   * 查询外部联系人详情
  */
  async getExternalDetail(params) {
    const res = await request.get(`${SERVER.QW_SERVICE}/api/v1/empExternal/detail`, { params })
    if (res.code !== 0 && params.externalUserId) { // uat临时方案
      return { ...params, externalUserid: params.externalUserId }
    }
    return thenResolve(res)
  },
  // 意向等级list
  getIntentionLevelList(params) {
    return request.post(`${SERVER.BASE_DATA_SERVICE}/api/v1/intentionLevel/list`, params).then(thenResolve)
  },
  // 关联微信
  setWechat(params) {
    return request.post(`${SERVER.LEAD_SERVICE}/api/v1/wechat`, params).then(thenResolve)
  },
  // 查询地理位置
  getAreaList(params) {
    return request.get(`${SERVER.BASE_DATA_SERVICE}/api/v1/area/list`, { params }).then(thenResolve)
  },
  // 查询门店
  cityDealersPost(params) {
    return request.post(`${SERVER.ADMIN_SERVICE}/api/v1/dealer/listByCity`, params).then(thenResolve)
  },
  // 查询外饰
  modelSalesColours(params) {
    return request.post(`${SERVER.BASE_DATA_SERVICE}/api/v1/modelSales/get-colours`, params).then(thenResolve)
  },
  // 查询内饰
  modelSalesInteriors(params) {
    return request.post(`${SERVER.BASE_DATA_SERVICE}/api/v1/modelSales/get-interiors`, params).then(thenResolve)
  },
  // 查询物料
  modelSalesMaterial(params) {
    return request.post(`${SERVER.BASE_DATA_SERVICE}/api/v1/modelSales/get-material`, params).then(thenResolve)
  },
  // 查询选装
  modelSalesOptions(params) {
    return request.post(`${SERVER.BASE_DATA_SERVICE}/api/v1/modelSales/get-options`, params).then(thenResolve)
  },
  // 门店详情
  getDealerDetail(params) {
    return request.get(`${SERVER.ADMIN_SERVICE}/api/v1/dealer/get`, { params }).then(thenResolve)
  },

  // 获取所有交付门店列表数据
  getDeliveryShopList(params) {
    return request.post(`${SERVER.ADMIN_SERVICE}/api/v1/dealer/listByCondition`, params).then(thenResolve)
  },

  // 通过客户ID查询当前员工对此是否有跟进线索
  hatchDetailByCustomerId(params) {
    return request.get(`${SERVER.LEAD_SERVICE}/api/v1/hatchDetailByCustomerId`, { params })
  },
  async getQwResourceNew(params) {
    const result = await request.get(`${SERVER.ADMIN_SERVICE}/api/v1/resource/qwResource`, { params })
    const { code: resCode, msg, data } = result
    if (resCode !== 0) {
      const message = msg || '加载企微资源发生错误'
      Toast.fail(message)
      throw new Error(message)
    }
    const menus = { menu: data, btn: {} }
    data.map(item => {
      if (item.resourcesType === 3) {
        menus.btn[item.code] = item
      }
    })
    store.commit('app/setMenu', JSON.stringify(menus))
    return data
  },
  // 画像，通过key查询字典
  getTagsDicsByKey(params) {
    return request.get(`${SERVER.BASE_DATA_SERVICE}/api/v1/labelMap/queryByKey`, { params }).then(thenResolve)
  },
  // 竞品列表
  getCompetitorModel(params) {
    return request.get(`${SERVER.BASE_DATA_SERVICE}/api/v1/competitorModel/list`, { params }).then(thenResolve)
  },
  // 查询经销商
  getDealerListApi: (params = {}) => request.post(`${SERVER.ADMIN_SERVICE}/api/v1/dealer/listByCondition`, params).then(thenResolve),
  // 获取战败原因树
  clueDefeatReasonTree(params) {
    return request.get(`${SERVER.BASE_DATA_SERVICE}/api/v1/defeatClue/defeatClueTree?bizType=${params.bizType}`).then(thenResolve)
  },
  // 查询客户顾虑树
  clueConcernsTree(params) {
    return request.get(`${SERVER.BASE_DATA_SERVICE}/api/v1/concern/tree`, { params }).then(thenResolve)
  },
}

export default services
