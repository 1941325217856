const toString = Object.prototype.toString
import { Toast } from 'vant'
import dayjs from 'dayjs'
export default null
import { i18n } from '@/main'
import store from '@/store'

// 解析url参数
export const parseQuery = (query) => {
  const res = {}
  query = query.trim().replace(/^(\?|#|&)/, '')
  if (!query) {
    return res
  }
  query.split('&').forEach((param) => {
    const parts = param.replace(/\+/g, ' ').split('=')
    const key = decodeURIComponent(parts.shift())
    const val = parts.length > 0 ? decodeURIComponent(parts.join('=')) : null
    if (res[key] === undefined) {
      res[key] = val
    } else if (Array.isArray(res[key])) {
      res[key].push(val)
    } else {
      res[key] = [res[key], val]
    }
  })
  return res
}

/**
 * 根据路径获取文件后缀
 * @param {String} path 路径
 */
export const getExtName = (path) => {
  // eslint-disable-next-line no-useless-escape
  const res = path.match(/\.[^\.]+$/)
  return res ? res[0] : ''
}

/**
 * 将费用数字格式化为带,的展示形式
 * @param {Number} amount 费用数字
 */
export const formatFee = (amount) =>
  amount ? amount.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : 0

export const isArray = (val) => toString.call(val) === '[object Array]'

export const joinOptions = (options, field) => {
  if (!Array.isArray(options)) return ''
  const nameList = options.map(item => item[field])
  return nameList.join('/')
}

export const getDictName = (full, type, dataSource, code, entire = false) => {
  const emu = full[type]?.dictList || []
  const temp = emu.find(item => item.dictCode === Number(dataSource[code]))
  if (entire) return temp
  return temp ? temp.dictName : ''
}
/**
 * PC端copy电话号码
*/
export const copyPhone = (phone, tips) => {
  if (navigator.userAgent.match(/AppleWebKit.*Mobile.*/)) {
    return
  }
  const input = document.createElement('input')
  input.value = phone
  document.body.appendChild(input)
  input.select()
  document.execCommand('copy')
  document.body.removeChild(input)
  Toast.success(tips || i18n.t('手机号复制成功'))
}
/**
 *复制
*/
export const copyText = (text, tips) => {
  const input = document.createElement('input')
  input.value = text
  document.body.appendChild(input)
  input.select()
  document.execCommand('copy')
  document.body.removeChild(input)
  Toast.success(tips || i18n.t('内容已复制'))
}

/**
 * 判断是否未移动端
*/
export const judgeMobile = () => {
  return (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))
}
/**
 * 判断浏览器环境是否是微信环境
 */
export const weixinEnv = () => {
  return /wxwork/i.test(navigator.userAgent)
}
/**
 * 下载文件
*/
export const saveFile = (result, options = {}) => {
  // 默认导出格式为 excel
  const { fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' } = options
  const blob = new Blob([result.data], { type: fileType })
  let downloadElement = document.createElement('a')
  let href = window.URL.createObjectURL(blob)
  downloadElement.href = href
  const filename = result.headers['content-disposition'].split(';')[1].split('=')[1]
  downloadElement.download = decodeURIComponent(filename) // 下载后文件名
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
  window.URL.revokeObjectURL(href)
}

/**
 * 防抖
 */
export const debounce = function (callback, delay = 1000) {
  let timer = null
  return function () {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      callback.call(this)
      timer = null
    }, delay)
  }
}
/**
 * 视频时长
 */
export const getVideoDurationByFile = (file) => {
  return new Promise((reslove, reject) => {
    try {
      let video = document.createElement('video')
      video.preload = 'metadata'
      video.src = window.URL.createObjectURL(file)
      video.onloadedmetadata = () => {
        reslove(video.duration)
        video = null
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const getWxEntry = () => {
  return new Promise((resolve, reject) => {
    wx.invoke('getContext', {}, function (res) {
      if (res.err_msg === 'getContext:ok') {
        resolve(res.entry)
      } else {
        reject(res.err_msg || res.errMsg)
      }
    })
  })
}
export const getWxUUid = () => {
  return new Promise((resolve) => {
    wx.invoke('getCurExternalContact', {}, function (res) {
      if (res.err_msg === 'getCurExternalContact:ok') {
        resolve(res.userId)
      } else {
        resolve(false)
      }
    })
  })
}

/**
 * @summary 打电话
 * @param {string|number} phoneNumber - 电话号码
 */
export const callPhone = (phoneNumber) => {
  const value = phoneNumber.toString().replace(/[^\d]+/g, '')
  const call = document.createElement('a')
  call.href = 'tel:' + value
  document.body.appendChild(call)
  const evt = document.createEvent('MouseEvents')
  evt.initEvent('click', false, false)
  call.dispatchEvent(evt)
  document.body.removeChild(call)
}

/* eventType is 'touchstart', 'touchmove', 'touchend'... */
export const sendTouchEvent = (clientX, clientY, element, eventType) => {
  const touchObj = new Touch({
    identifier: Date.now(),
    target: element,
    clientX,
    clientY,
    radiusX: 2.5,
    radiusY: 2.5,
    rotationAngle: 10,
    force: 0.5,
  })

  const touchEvent = new TouchEvent(eventType, {
    cancelable: true,
    bubbles: true,
    touches: [touchObj],
    targetTouches: [],
    changedTouches: [touchObj],
    shiftKey: true,
  })

  element.dispatchEvent(touchEvent)
}
export const isUrl = (path) => {
  const reg =
    /(https?:\/\/)?(([0-9a-z.]+\.[a-z]+)|(([0-9]{1,3}\.){3}[0-9]{1,3}))(:[0-9]+)?(\/[0-9a-z%/.\-_]*)?(\?[0-9a-z=&%_\-]*)?(\#[0-9a-z=&%_\-]*)?/ig
  return reg.test(decodeURIComponent(path))
}

// 定义url字符串拼接的方法
export const setUrlQuery = (options) => {
  let { url, query } = options
  if (!url) return ''
  if (query) {
    let queryArr = []
    for (const key in query) {
      if (query.hasOwnProperty(key)) {
        queryArr.push(`${key}=${query[key]}`)
      }
    }
    if (url.indexOf('?') !== -1) {
      url = `${url}&${queryArr.join('&')}`
    } else {
      url = `${url}?${queryArr.join('&')}`
    }
  }
  return url
}
/**
 * @summary 拼接 uri 与 query 请求参数
 * @param {string} uri - 资源路径
 * @param {object} params - query 请求入参
 * @return {string} - url
 */
export const recombineUrlParams = (uri, params = {}) => {
  const { uri: Uri, params: Params } = getUrlParams(uri)
  const url = Object.entries({ ...Params, ...params }).reduce((pre, [key, val]) => {
    if (Array.isArray(val)) {
      pre += `${key}=${val.join(`&${key}=`)}&`
    } else {
      pre += `${key}=${val}&`
    }
    return pre
  }, Uri + '?')
  return url.slice(0, -1)
}
/**
 * @summary 获取 url 中的 uri 与 query 请求参数
 * @param {string} url - 请求地址 url
 * @return {object} - { uri, params }
 */
export const getUrlParams = (url) => {
  const regList = url.match(/[^(?|&)]*/g).filter(item => item)

  const uri = regList.shift()

  const params = regList.reduce((pre, cur) => {
    const [key, value] = cur.split('=')
    if (pre[key]) {
      if (Array.isArray(pre[key])) {
        pre[key].push(value)
      } else {
        pre[key] = [pre[key], value]
      }
    } else {
      pre[key] = value
    }
    return pre
  }, {})

  return { uri, params }
}

// 函数截流
export const throttle = (fn, delay) => {
  return function (...args) {
    let nowTime = +new Date()
    if (!fn.lastTime || nowTime - fn.lastTime > delay) {
      fn.apply(this, args)
      fn.lastTime = nowTime
    }
  }
}

export const thenResolve = (res) => {
  const { code, data, msg, message } = res || {}
  if (code === 0) {
    return typeof (data) === 'boolean' ? data : (data || {})
  }
  if (code !== 14) {
    Toast(msg || message)
  }
  throw new Error(res?.data || res.code || msg || message || '')
}

export const dateFormat = (val, type = 'YYYY/MM/DD') => {
  return val ? dayjs(val).format(type) : val
}

export const arrayFormat = (arr, params = {}) => {
  const { name = 'name', value = 'id', nameKey = 'label', valueKey = 'value', cb = () => ({}) } = params
  const list = []
  arr.map(item =>{
    if (item){
      list.push({
        [nameKey]: item[name],
        [valueKey]: item[value],
        ...item,
        ...cb(item),
      })
    }
  })
  return list
}

// 向上取整
export const padTime = (timeStr) => {
  // timeStr = timeStr.replace(/-/g, '/')
  var oDate = new Date(timeStr)
  var stamp = oDate.getTime()
  var minute = oDate.getMinutes()
  var last = minute % 10
  if (last) {
    stamp += (10 - last) * 60 * 1000
  }
  oDate = new Date(stamp)

  var t = {
    year: pad_2_0(oDate.getFullYear()),
    month: pad_2_0(oDate.getMonth() + 1),
    day: pad_2_0(oDate.getDate()),
    hour: pad_2_0(oDate.getHours()),
    minute: pad_2_0(oDate.getMinutes()),
    second: pad_2_0(oDate.getSeconds())
  }

  var res = t.year + '/' + t.month + '/' + t.day + ' ' + t.hour + ':' + t.minute

  console.log(timeStr, '=>', res)
  return res
  function pad_2_0(num) {
    return num >= 10 ? num : '0' + num
  }
}

export const getArrToOBj = (arr = [], key = 'code') => {
  const obj = {}
  arr.map(item => {
    obj[item[key]] = item
  })
  return obj
}
// 设定的时间小于当前时间，取当前时间
export const getTimeNowOrOld = (appointmentStartTime, appointmentEndTime) => {
  const nowDay = dayjs()
  const nowDdate = dateFormat(nowDay, 'YYYY-MM-DD')
  const nowStartTime = dateFormat(padTime(nowDay.add(10, 'minute')), 'HH:mm')
  const nowEndTime = dateFormat(padTime(nowDay.add(40, 'minute')), 'HH:mm')
  // console.log(nowStartTime, 5656)
  let timeObj = {}
  if (appointmentStartTime && appointmentEndTime) {
    // 预约时间是否小于当前时间
    if (dayjs(appointmentStartTime).isBefore(dayjs(nowDdate + ' ' + nowStartTime), 'minute')) {
      timeObj = {
        start: dateFormat(nowDdate + ' ' + nowStartTime + ':00', 'YYYY/MM/DD HH:mm:ss'),
        end: dateFormat(nowDdate + ' ' + nowEndTime + ':59', 'YYYY/MM/DD HH:mm:ss')
      }
    } else {
      timeObj = {
        start: dateFormat(appointmentStartTime, 'YYYY/MM/DD HH:mm:ss'),
        end: dateFormat(appointmentEndTime, 'YYYY/MM/DD HH:mm:ss')
      }
    }
  }
  return timeObj
}

// 获取指定时间最近的整点或半点
export const getNearHour = (date) => {
  const start = dayjs(date).second(0)
  const hour = Number(start.format('HH'))
  const minute = Number(start.format('mm'))
  const nextHour = hour + 1
  if (minute < 30) {
    return start.minute(30).toDate()
  } else {
    return start.hour(nextHour).minute(0).toDate()
  }
}

export const isMobilePhone = (phoneNumber) => {
  return phoneNumber ? /^1\d{10}$/.test(phoneNumber) : ''
}

export const calculateCode = (status) => {
  if (status == null || status === undefined) return ''
  const { dictHash } = store.getters
  status = status.trim()
  var dict = String(status).substring(0, 4)
  if (dictHash && dictHash[dict] && Array.isArray(dictHash[dict])) {
    const obj = dictHash[dict].find(({ code }) => code === status)
    if (obj) {
      return obj.name
    }
  }
  if (status) {
    return ''
  } else {
    return '--'
  }
}

// 今年的日期得到月日，其他日期得到年月日
export const getTimeTextYMD = (data) => {
  if (!data) return '--'
  const year = data.split('-')[0]
  const theYear = new Date().getFullYear()
  if (year != theYear) {
    return dateFormat(data, 'YYYY-MM-DD')
  } else {
    return dateFormat(data, 'MM-DD')
  }
}
