export default {
  '您的试驾体验专属瞬间': '您的试驾体验专属瞬间',
  '恭喜您荣膺': '恭喜您荣膺',
  '本次试驾获取的': '本次试驾获取的',
  '积分': '积分',
  '成长值': '成长值',
  '点击了解定级规则': '点击了解定级规则',
  '您的驾驶表现': '您的驾驶表现',
  '试驾信息回顾': '试驾信息回顾',
  '试驾报告试驾车型': '试驾车型',
  '试驾路程': '试驾路程',
  '试驾时长': '试驾时长',
  '试驾报告最高车速': '最高车速',
  '与传统燃油车相比，这次试驾行驶预计可为您节省费用': '与传统燃油车相比，这次试驾行驶预计可为您节省费用',
  '元': '元',
  '驾驶ID. UNYX，让节能环保成为您的日常选择': '驾驶ID. 与众，让节能环保成为您的日常选择',
  '试驾门店': '试驾门店',
  '您的专属服务顾问': '您的专属服务顾问',
  '85%的用户还会想了解': '85%的用户还会想了解',
  '试驾有礼': '试驾有礼',
  '尚未深度体验': '尚未深度体验',
  '定级规则': '定级规则',
  '扫描二维码并预约试驾': '扫描二维码并预约试驾',
  '领取精美试驾礼品': '领取精美试驾礼品',
  '试驾报告领取试驾礼品': '领取试驾礼品',
  '礼物已领取': '礼物已领取',
  '播放演示视频': '播放演示视频',
  '长按海报可保存至本地哦': '长按海报可保存至本地哦',
  '海报图片生成中，请耐心等候!': '海报图片生成中，请耐心等候!',
  '未中奖': '未中奖'
}
