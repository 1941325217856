export default {
  '试驾客户信息': 'Customer information',
  '报告预览': 'preview',
  '发送报告': 'send',
  '姓名': 'Name',
  '电话': 'Phone',
  '身份证': 'ID card',
  '销售顾问': 'Sales consultant',
  '试驾类型': 'Test drive type',
  '试驾项目': 'Test drive items',
  '试驾车型': 'Test drive vehicle model',
  '试驾车辆': 'Test drive vehicle',
  '礼物领取方式': 'Gift collection method',
  '试驾预约时间': 'Test drive appointment time',
  '实际试驾时间': 'Actual test drive time',
  '驾驶证': 'Driver\'s license',
  '查看照片': 'View photos',
  '试驾协议': 'Test drive agreement',
  '查看协议': 'View agreement',
  '个人信息声明': 'Personal information statement',
  '试驾录音': 'Test drive recording',
  '试驾数据记录': 'Test drive data record',
  '试驾行驶数据': 'Test drive driving data',
  '平均时速': 'Average speed',
  '最高时速': 'Top speed',
  '急加速': 'Hard acceleration',
  'Travel Assist启动': 'Travel assist activation',
  '车辆转向': 'Vehicle steering',
  '行驶时长': 'Driving duration',
  '行驶功耗': 'Driving energy consumption',
  'One-App控车': 'One-App vehicle control',
  'APP查看车辆状态': 'Check vehicle status via APP',
  'APP查看用车报告': 'View driving report via APP',
  'APP控制车辆空调': 'Control vehicle air conditioning via APP',
  'APP寻找车辆': 'Find vehicle via APP',
  '车联网体检': 'Internet of vehicles health check',
  'SDS车辆控制': 'SDS vehicle control',
  'SDS导航使用': 'SDS navigation usage',
  '在线音乐播放': 'Online music playback',
  'avatar商店': 'Avatar store',
  'APP使用记录': 'APP usage records',
  '智能泊车': 'Smart parking',
  'IPA泊车': 'IPA parking',
  '远程泊车': 'Remote parking',
  '车辆充电': 'Vehicle charging',
  '充电体验': 'Charging experience',
  '短信': 'SMS',
  '微信': 'WeChat',
  '未上传驾照': 'Driver\'s license not uploaded',
  '未上传协议': 'Agreement not uploaded',
  '试驾报告': 'Test drive report',
  '您好！感谢体验': 'Hello! Thank you for the experience!',
  '，已为您生成专属试驾报告，回顾智驾体验！': ', a personalized test drive report has been generated for you, reviewing your smart driving experience!',
  '发送成功': 'Sent successfully',
  '试驾GPS轨迹图': 'Test drive GPS trajectory map',
  '客户未授权个人信息': 'The customer has not authorized personal information',
  '暂无轨迹数据': 'No data available at the moment',
  '预约人姓名': 'Appointment Name',
  '预约人电话': 'Appointment phone number',
  '试驾人姓名': 'Test driver\'s name',
  '试驾人电话': 'Test driver\'s phone number',
  '试驾证件号': 'Test drive certificate number',
  '试驾接待人': 'Test drive receptionist',
  'ID. 与众试驾报告': 'ID. UNYX Test Drive Report',
  '尊敬的客户，感谢体验ID. 与众，已为您生成专属试驾报告，点击开启您的试驾回顾之旅！': 'Dear customer, thank you for experiencing ID. UNYX, we have generated an exclusive test drive report for you. Click to start your test drive review journey!',
}
