import i18nFun from '../router_i18n'
const appName = i18nFun['提车礼品报缺']
const routerPath = 'giftDeletion'
const routes = [
  {
    path: '/gift-deletion-list',
    name: 'GiftDeletionList',
    component: () => import('@/modules/giftDeletion/index'),
    meta: {
      title: i18nFun['提车礼品报缺'],
      appName,
      isAppIndex: true,
      routerPath
    },
  },
  {
    path: '/gift-deletion-add',
    name: 'GiftDeletionAdd',
    component: () => import('@/modules/giftDeletion/add'),
    meta: {
      title: i18nFun['提车礼品报缺'],
      appName,
      isAppIndex: true,
      routerPath
    },
  }
]

export default routes
