<template>
  <div class="filters">
    <van-popup
      :value="value"
      position="right"
      :lazy-render='false'
      :style="{ minWidth: isPC ? '400px' : '335px', width: '80%', height: '100%' }"
      :class="[isPC ? 'pc' : '']"
      @click-overlay="onClose"
    >
      <!-- 头部 -->
      <div v-if="isPC" class="header-box">
        <div class="name">全部筛选</div>
        <div class="icons" @click="onClose">
          <van-icon color="#636E80" name="cross" />
        </div>
      </div>

      <van-collapse v-model="activeKeys" class="collapses">
        <van-collapse-item
          v-for="(item, index) of getOptions"
          :ref="`${item.type}_${item.field}_${item.uuid}`"
          :key="item.uuid"
          :title="item.name"
          :name="item.field"
        >
          <!-- 插槽 -->
          <template v-if="item.titleValue" #value>
            <span style="font-size: 11px">{{ item.titleValue }}</span>
          </template>

          <!-- 插槽，右侧ICON -->
          <!-- 如果启用tagsInput框，则禁用右侧图标 -->
          <template v-if="item.tagsInput && activeKeys.includes(item.field)" #right-icon><i></i></template>

          <!-- 快捷标签输入框 -->
          <CommonSelectTagsInput
            v-if="item.tagsInput"
            v-bind="item"
            :tags="item.checkedObjectArray"
            style="margin-bottom: 6px;"
            @delete="tagValue => handleTagsInputDelete(tagValue, item, index)"
            @clickRight="handleTagsInputClickRight(item, index)"
          >
          </CommonSelectTagsInput>

          <!-- 选择器 -->
          <CommonSelect
            v-if="item.type === 'SELECT'"
            v-bind="item"
            @change="(values) => handleSelectChange(values, item)"
          >
          </CommonSelect>

          <!-- 日期 -->
          <CommonDate
            v-if="item.type === 'DATE'"
            v-bind="item"
            :isPC="isPC"
            @change="(values) => handleDateChange(values, item)"
            @titleValueUpdate="(value) => handleTitleValueUpdate(value, item)"
          >
          </CommonDate>

          <!-- 级联选择器 -->
          <CommonCascader
            v-if="item.type === 'CASCADER'"
            :ref="`${item.type}_${item.field}`"
            v-bind="item"
            @change="(values) => handleCascaderChange(values, item)"
          >
          </CommonCascader>

          <!-- 人员筛选 -->
          <BusinessPeopleSelection
            v-if="item.type === 'B_PEOPLE_SELECTION'"
            :ref="`${item.type}_${item.field}`"
            v-bind="item"
            @change="(values) => handlePeopleChange(values, item)"
          >
          </BusinessPeopleSelection>

          <!-- 输入框组 -->
          <CommonInputNumbers
            v-if="item.type === 'INPUT_NUMBERS'"
            v-bind="item"
            @change="(values) => handleInputNumbersChange(values, item)"
          >
          </CommonInputNumbers>
          
        </van-collapse-item>
      </van-collapse>

      <div class="bottoms">
        <van-button round plain type="default"  class="btn" @click="handleReset">{{ buttonNameReset }}</van-button>
        <van-button round  type="primary" class="btn" @click="handleOK">{{ buttonNameOK }}</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
// Tools
import { v4 as uuidv4 } from 'uuid'
import { isArray, cloneDeep } from 'lodash'
import { i18n } from '@/main'
import BusinessPeopleSelection from '@/components/v2/business/BusinessPeopleSelection/index.vue'
import CommonSelect from '@/components/v2/system/CommonSelect/index.vue'

export default {
  name: 'filters',
  components: {
    BusinessPeopleSelection,
    CommonSelect
  },
  props: {
    options: {
      // 选项
      type: Array,
      default: () => [],
    },
    value: {
      // 是否显示
      type: Boolean,
      default: false,
    },
    // 默认的面板
    defaultActiveKey: {
      type: Array,
      default: () => [],
    },
    // PC
    isPC: {
      type: Boolean,
      default: false,
    },
    submitWhenClose: {
      // 关闭时是否提交
      type: Boolean,
      default: false,
    },
    buttonNameOK: {
      type: String,
      default: ()=>(i18n.t('确认'))
    },
    buttonNameReset: {
      type: String,
      default: '重置'
    }
  },
  data() {
    return {
      activeKeys: [],
      items: [],
      formData: {},
    }
  },
  computed: {
    getOptions() {
      return this.items.filter((i) => !i.hidden)
    },
  },
  watch: {
    defaultActiveKey: {
      handler() {
        console.log('[watch] defaultActiveKey', this.defaultActiveKey)
        this.activeKeys = this.defaultActiveKey
      },
      deep: true,
    },
    options: {
      handler() {
        console.log('[watch] options', this.options)
        this.init()
      },
      deep: true,
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      console.log('[init] filters', this.options)
      this.activeKeys = this.defaultActiveKey
      this.initOptions()
    },
    initOptions() {
      this.items = cloneDeep(this.options)
        .filter((i) => i.field)
        .map((item) => {
          let { uuid, field, fields, defaultValue, multiple, items = [], level1Merges = [] } = item

          // 空值
          const nullValue = multiple ? [] : ''

          // 准备表单数据
          if (fields && fields.length) {
            fields.forEach((field, index) => {
              this.$set(this.formData, field, defaultValue && defaultValue[index] || nullValue)
            })
          } else {
            // 处理值类型
            if (item.valueType === 'object') {
              defaultValue = multiple ? items.filter(i => defaultValue.includes(i.value) && i.value) : items.find(i => i.value === defaultValue) || {}
            }
            this.$set(this.formData, field, defaultValue ?? nullValue)
          }

          // 拦截代理onClick
          const setOnClickProxy = (itemOfArray) => {
            const { onClick: oldOnClick, name } = itemOfArray
            if (oldOnClick) {
              console.log('[initOptions] 拦截代理onClick', name)
              itemOfArray.onClick = (values) => oldOnClick(values, item)
            }
          }

          items.forEach(item => setOnClickProxy(item))
          level1Merges.forEach(item => setOnClickProxy(item))

          // 生成uuid
          item.uuid = uuid || uuidv4()

          // hidden
          item.hidden = item.hidden || false

          return item
        })

      console.log('[initOptions] formData', this.formData)

      const readyObject = {
        formData: this.formData,
        items: this.items,
      }
      this.$emit('ready', readyObject)
    },
    handleDealersChange(value, item) {
      console.info('handleSelectChange', value, item)
      const { field } = item
      this.$set(this.formData, field, value)
      console.log('[handleSelectChange] formData', this.formData)
      this.emitUpdate(item)
    },
    handleSelectChange(value, item) {
      console.info('handleSelectChange', value, item)
      const { field } = item
      this.$set(this.formData, field, value)

      console.log('[handleSelectChange] formData', this.formData)

      this.emitUpdate(item)
    },
    handleTitleValueUpdate(value, item) {
      console.info('handleTitleValueUpdate', value, item)
      item.titleValue = value
    },
    handleDateChange(value, item) {
      console.info('handleDateChange', value, item)
      const { field, fields = [] } = item
      // this.$set(this.formData, field, value)

      fields.forEach((keyName, index) => this.$set(this.formData, keyName, value[index]))

      console.log('[handleDateChange] formData', this.formData)

      this.emitUpdate(item)
    },
    handleCascaderChange(value, item) {
      console.info('handleCascaderChange', value, item)
      const { checkedObjectArray = [] } = value
      this.$set(item, 'checkedObjectArray', checkedObjectArray)
    },
    handleBrandCarsCascaderChange(value, item) {
      console.info('handleBrandCarsCascaderChange', value, item)
      let { checkedOnlyArray = [], checkedObjOnlyArray = [], checkedObjectArray = [] } = value // 后端用or实现，所以这里只需要传给后端选中的最后一级
      const { fields = [], brandMultiple, valueType = 'value' } = item
      this.$set(item, 'checkedObjectArray', checkedObjectArray)

      // 兼容处理品牌多选单选功能
      if (!brandMultiple) {
        checkedOnlyArray[0] = isArray(checkedOnlyArray[0]) ? checkedOnlyArray[0].join(',') : checkedOnlyArray[0]
        checkedObjOnlyArray[0] = isArray(checkedObjOnlyArray[0]) ? checkedObjOnlyArray[0][0] : checkedObjOnlyArray[0]
      }

      // 设置formData
      fields.forEach((keyName, index) => {
        const formDataValue = valueType === 'value' ? checkedOnlyArray[index] : checkedObjOnlyArray[index]
        this.$set(this.formData, keyName, formDataValue)  
      })

      this.emitUpdate(item)
    },
    handleChannelsCascaderChange(value, item) {
      console.info('handleChannelsCascaderChange', value, item)
      const { checkedOnlyArray = [], checkedObjOnlyArray = [], checkedObjectArray = [] } = value // 后端用or实现，所以这里只需要传给后端选中的最后一级
      const { fields = [], firstMultiple, valueType = 'value', } = item
      this.$set(item, 'checkedObjectArray', checkedObjectArray)

      // 兼容处理多选单选功能
      if (!firstMultiple) {
        checkedOnlyArray[0] = isArray(checkedOnlyArray[0]) ? checkedOnlyArray[0].join(',') : checkedOnlyArray[0]
      }

      // 设置formData
      fields.forEach((keyName, index) => {
        const formDataValue = valueType === 'value' ? checkedOnlyArray[index] : checkedObjOnlyArray[index]
        this.$set(this.formData, keyName, formDataValue)  
      })
      this.emitUpdate(item)
    },
    handleTagsInputDelete(tagValue, item, index) {
      console.info('handleTagsInputDelete', tagValue, item, index, { defaultValue: item.defaultValue?.length })

      const refName = `${item.type}_${item.field}`
      const refNode = this.$refs[refName][0]
      refNode.CHECK_OFF_ITEM(tagValue)
    },
    handleTagsInputClickRight(item, index) {
      console.info('handleTagsInputClickRight', item, index)
      const refName = `${item.type}_${item.field}_${item.uuid}`
      const refNode = this.$refs[refName][0]
      console.log('[handleTagsInputClickRight] refNode', refNode)
      refNode.toggle()
    },
    handlePeopleChange(value, item){
      console.info('handlePeopleChange', value, item)
      const { field, valueType = 'value' } = item

      this.$set(item, 'checkedObjectArray', cloneDeep(value).map(i => [i]))

      if (valueType === 'value') {
        value = value.map(i => i.value)
      }
      this.$set(this.formData, field, value)
    },
    handleInputNumbersChange(value, item) {
      console.info('handleInputNumbersChange', value, item)
      const { field, fields = [], nullables = [], } = item

      // 设置formData
      fields.forEach((keyName, index) => {
        const nullable = nullables[index]
        const formDataValue = nullable ? value[index] : value[index] || 0
        this.$set(this.formData, keyName, formDataValue)  
      })
     
      this.emitUpdate(item)
    },
    emitUpdate(item) {
      // 更新
      if (!item || !item.onChange) return
      item.onChange(this.formData, item)
    },
    emitSubmit() {
      // 提交
      this.$emit('submit', this.formData)
    },
    onClose() {
      // 关闭
      if (this.submitWhenClose) {
        this.emitSubmit()
      }
      this.$emit('input', false)
    },
    handleReset() {
      // 重置
      this.items = []
      this.$nextTick(() => {
        this.init()
        this.$emit('reset')
      })
    },
    handleOK() {
      // 确定
      this.emitSubmit()
      this.onClose()
    },
  },
}
</script>

<style lang="less" scoped>
.filters {
  /deep/ .van-cell {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 100;
    background-color: #fff;
  }

  /deep/ .van-cell__value {
    min-width: 150px;
  }

  .pc {
    width: 376px !important;
    height: calc(100vh - 30px) !important;
    border-radius: 20px 0 0 20px !important;

    /deep/ .van-cell {
      position: sticky;
      position: -webkit-sticky;
      top: 52px;
    }

    .collapses {
      min-height: calc(100vh - 160px);
    }
  }

  .header-box {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    padding: 16px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background-color: #fff;
    z-index: 102;
    // box-shadow: 0px -1px #e7e8ea inset;
    border-bottom: 1px solid #f8f8f8;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   border-bottom: 1px solid #f8f8f8;
    //   transform: scaleY(.5);
    //   transform-origin: left top;
    // }

    .name {
      flex: 1;
    }

    .icons {
      cursor: pointer;
    }
  }
  .collapses {
    min-height: calc(100vh - 80px);
  }
  .bottoms {
    padding: 16px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 101;
    background-color: #fff;
    border-top: 1px solid #f8f8f8;
    // &:before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   border-top: 1px solid #f8f8f8;
    //   transform: scaleY(.5);
    //   transform-origin: left top;
    // }
    .btn {
      flex: 1;
      &:first-child {
        margin-right: 14px;
      }
    }
  }
}
</style>
