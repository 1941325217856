export default {
  '线下下单': '线下下单',
  '待开发': '待开发',
  '审核内容': '审核内容',
  '申请人': '申请人',
  '申请转入门店': '申请转入门店',
  '申请说明': '申请说明',
  '线索转入': '线索转入',
  '订单修改': '订单修改',
  '驳回原因': '驳回原因',
  '不超过200字': '不超过200字',
}
