
const routerPath = 'inspectionManage'
import i18nFun from '../router_i18n'
const appName = i18nFun.试驾车点检
const routes = [
  {
    path: '/inspection-list',
    name: 'InspectionList',
    component: () => import('@/modules/inspectionManage/list.vue'),
    meta: {
      title: i18nFun.试驾车点检,
      appName,
      isAppIndex: true,
      routerPath,
    },
  },
  {
    path: '/inspection-operate',
    name: 'InspectionOperate',
    component: () => import('@/modules/inspectionManage/operate.vue'),
    meta: {
      title: i18nFun.车辆点检,
      appName,
      isAppIndex: true,
      routerPath,
    },
  },
  {
    path: '/inspection-history',
    name: 'InspectionHistory',
    component: () => import('@/modules/inspectionManage/history.vue'),
    meta: {
      title: i18nFun.点检记录,
      appName,
      isAppIndex: true,
      routerPath,
    },
  },
]

export default routes
