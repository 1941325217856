const ADMIN_SERVICE = '/admin-service'
const QW_SERVICE = '/qw-service'
const BASE_DATA_SERVICE = '/base-data-service'
const LEAD_SERVICE = '/scrm-lead-service'
const STATISTICS_SERVICE = '/scrm-statistics-service'
const Test_Drive = '/test-drive'
const THIRD_SYSTEM_SERVICE = '/scrm-third-system-service'
const ORDER_SERVICE = '/scrm-order-service'
const SPEECHCRAFT_SERVICE = '/scrm-speechcraft'
const SOP_SERVISE = '/scrm-sop-task'
const QUESTION_SERVICE = '/scrm-questionnaire-service'

export default {
  ADMIN_SERVICE,
  QW_SERVICE,
  BASE_DATA_SERVICE,
  LEAD_SERVICE,
  STATISTICS_SERVICE,
  Test_Drive,
  THIRD_SYSTEM_SERVICE,
  ORDER_SERVICE,
  SPEECHCRAFT_SERVICE,
  SOP_SERVISE,
  QUESTION_SERVICE
}
