import i18nFun from '../router_i18n'
const appName = i18nFun['充电桩白名单']
const routerPath = 'chargeManage'
const routes = [
  {
    path: '/charge-manage',
    name: 'chargeManage',
    component: () => import('@/modules/charge/index'),
    meta: {
      title: i18nFun['充电桩白名单'],
      appName,
      isAppIndex: true,
      routerPath
    }
  }
]
export default routes
