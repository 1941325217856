import i18nFun from '../router_i18n'
const appName = i18nFun['销售助手']
const routerPath = 'salesAssistant'

const routes = [
  {
    path: '/salesAssistant',
    name: 'SalesAssistant',
    component: () => import('@/modules/salesAssistant/index'),
    meta: {
      title: i18nFun['销售助手'],
      appName,
      isAppIndex: true,
      routerPath
    }
  },
  {
    path: '/salesAssistant/staging',
    name: 'Staging',
    component: () => import('@/modules/salesAssistant/staging'),
    meta: {
      title: i18nFun['工作台'],
      appName,
      isAppIndex: true,
      routerPath
    }
  },
  {
    path: '/testDriveSchedule',
    name: 'testDriveSchedule',
    component: () => import('@/modules/salesAssistant/testDriveSchedule.vue'),
    meta: {
      title: i18nFun['新建试驾排程'],
      appName,
      isAppIndex: true,
      routerPath
    }
  },
  {
    path: '/schedulingDrive',
    name: 'SchedulingDrive',
    component: () => import('@/modules/salesAssistant/components/schedulingDrive.vue'),
    // meta: {
    //   title: i18nFun['新建试驾排程'],
    //   appName,
    //   isAppIndex: true,
    //   routerPath
    // }
    meta: {
      routerPath
    },
    beforeEnter(to, from, next) {
      to.meta.appName = appName
      const { tableType } = to.query
      if (tableType === 'CONFIRMED') {
        to.meta.title = i18nFun['排程量']
      } else if (tableType === 'COMPLETED') {
        to.meta.title = i18nFun['试驾量']
      }
      next()
    },
  },
]

export default routes
