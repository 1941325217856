const routerPath = 'orderManage'
import i18nFun from '../router_i18n'
const appName = i18nFun.订单明细
const routes = [
  {
    path: '/refundOrder',
    name: 'RefundOrder',
    component: () => import('@/modules/refundOrderManage/index'),
    meta: {
      title: i18nFun.申请退单,
      appName,
      isAppIndex: true,
      routerPath,
    },
  },
]

export default routes
