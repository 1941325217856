export default {
  '未在店内查询到该客户': '未在店内查询到该客户',
  '立即创建新线索': '立即创建新线索',
  '正在跟进该客户，你无法发起线下下单': '正在跟进该客户，你无法发起线下下单',
  '自然进店': '自然进店',
  '门店新建': '门店新建',
  '新建成功': '新建成功',
  '请输入电话号码完整搜索 / 或客户姓名': '请输入电话号码完整搜索 / 或客户姓名',
  '共': '共',
  '条数据': '条数据',
  '线上签署': '线上签署',
  '线下签署': '线下签署',
  '请及时代客户锁单': '请及时代客户锁单',
  '请联系客户及时支付定金': '请联系客户及时支付定金',
  '确认提交审核？': '确认提交审核？',
  '取消申请后，所有申请数据将被删除，确认取消 ？': '取消申请后，所有申请数据将被删除，确认取消 ？',
  '提交成功': '提交成功',
  '确认审核通过？': '确认审核通过？',
  '请填写驳回原因': '请填写驳回原因',
  '确认推送合同至客户签署 ？': '确认推送合同至客户签署 ？',
  '请上传文件': '请上传文件',
  '线下下单用户': '线下下单用户',
  '创建时间': '创建时间',
  '用户订单': '用户订单',
  '订单号': '订单号',
  '车系车型': '车系车型',
  '定金协议': '定金协议',
  '购车合同': '购车合同',
  '请拍摄并按顺序上传定金协议': '请拍摄并按顺序上传定金协议',
  '推送合同至客户签署': '推送合同至客户签署',
  '驳回原因': '驳回原因',
  '提交审核': '提交审核',
  '同意': '同意',
  '驳回': '驳回',
  '取消申请': '取消申请',
  '关闭': '关闭',
  '去锁单': '去锁单',
  '查看订单': '查看订单',
  '代客下单': '代客下单',
  '线下下单': '线下下单',
  '提示': '提示',
  '请输入': '请输入',
  '待审核': '待审核',
  '通过': '通过',
  '已审核': '已审核',
  '已驳回': '已驳回',
  '申请状态': '申请状态',
  '销售顾问': '销售顾问',
  '该客户还未分配销售顾问': '该客户还未分配销售顾问',
  '立即领取': '立即领取',
  '发起线下下单申请': '发起线下下单申请',
  '请拍摄并按顺序上传购车合同': '请拍摄并按顺序上传购车合同',
  '请拍摄并按顺序上传个人信息处理告知同意书': '请拍摄并按顺序上传个人信息处理告知同意书'
}
