import i18nFun from '../router_i18n'
const appName = i18nFun.离职继承
const routerPath = 'leaveJob'

const routes = [
  {
    path: '/leave-job',
    name: 'leaveJob',
    component: () => import('@/modules/leave-job/index.vue'),
    meta: {
      title: i18nFun.离职继承,
      appName,
      isAppIndex: true,
      routerPath
    }
  }
]

export default routes
