export const NEW_ORDER_STATUS = {

}

export const ROLES = {
  DCC: '1014001', // DCC专员
  SALES: '1014002', // 顾问
  SHOWOWNER: '1014003', // 店长
  MASTER: '1014004', // master
  BUDDY: '1014005', // buddy
  DELIVERSALES: '1014006', // 交付专员
  DELIVERMANAGE: '1014007', // 交付店店长
  CITYMANAGER: '1014030', // 城市经理
  REGIONMANAGER: '1014021', // 区域经理
  CITYMANAGER: '1014030', // 城市经理
}

// 预约状态(0：已取消、1：待确认、2：已确认、3：已完成)")
export const appointmentStatus = {
  0: '已取消',
  1: '待确认',
  2: '已确认',
  3: '已完成',
}

const configData = {
  dev:{
    configuration:'https://uat-www.idux-vw.com/configuration', // 车辆配置
    purchaseOrder:'https://uat-www.idux-vw.com/purchase-order' // 订单
  },
  sit:{
    configuration:'https://uat-www.idux-vw.com/configuration',
    purchaseOrder:'https://uat-www.idux-vw.com/purchase-order'
  },
  uat:{
    configuration:'https://uat-www.idux-vw.com/configuration',
    purchaseOrder:'https://uat-www.idux-vw.com/purchase-order'
  },
  pro:{}
}

export const configEnvData = configData[process.env.VUE_APP_ENV || 'dev']
