import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueResouse from 'vue-resource'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import { parseQuery } from '@/utils'
import VConsole from 'vconsole'
import Empty from '@/components/v2/common/empty'
import Vant, { Dialog, Lazyload, Locale } from 'vant'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import enUS from 'vant/es/locale/lang/en-US'
import { getWhilePath } from '@/router/whiteList'
import '@vant/touch-emulator'
import beforeLoadTaskServices from '@/services/beforeLoadTaskServices'
// 按钮自定义指令
import directives from '@/directives'
// 注册指令
Object.keys(directives).forEach((k) => Vue.directive(k, directives[k]))

import '@/filters'
import '@/mixins'

import 'vant/lib/index.less'
import '@/styles/index.less'
import moment from 'moment'
// 引入animate.css
import animated from 'animate.css'
import VueAnimateNumber from 'vue-animate-number'
import BaseDialog from '@/components/BaseDialog'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
// import wx16 from 'jweixin-1.6.0'
Vue.component('BusinessFilters', BusinessFilters)
Vue.component('Empty', Empty)
Vue.use(VueI18n)
Vue.use(BaseDialog)
Vue.use(VueAnimateNumber)
Vue.use(animated)
Vue.use(VueResouse)
Vue.prototype.$moment = moment
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 1500 }
// Vue.prototype.$jweixin16 = wx16
Vue.use(Vant)
// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true,
})

// 国际化
import messages from '@/lang/index.js'
// 获取浏览器系统语言
const language = window.navigator.language.toLocaleLowerCase()
// const language = 'en-us'
export const i18n = new VueI18n({
  locale: language.includes('en') ? 'en' : 'zh', // 设置语言
  // locale: 'zh', // 设置语言
  messages, // 设置地区信息
})
language.includes('en') ? Locale.use('en-US', enUS) : Locale.use('zh-CN', zhCN) // vant组件切换语言
// 设置语言环境 en / zh
Vue.prototype.$setLang = function (lang) {
  i18n.locale = lang
}

// 是否是移动端
Vue.prototype.$isMobile = /android|webos|iphone|ipad|blackberry/i.test(navigator.userAgent.toLowerCase())

Vue.config.productionTip = false
if (process.env.VUE_APP_VCONSOLE == 'true' || process.env.NODE_ENV === 'development') {
  const vConsole = new VConsole()

  setTimeout(() => {
    try {
      document.getElementById('__vconsole').setAttribute('data-no-touch-simulate', '')
    } catch (error) {
      console.info('error with vConsole', error)
    }
  }, 100)
  try {
    document.getElementById('__vconsole').setAttribute('data-no-touch-simulate', '')
  } catch (error) {
    console.info('error with vConsole', error)
  }
}
// if (process.env.NODE_ENV === 'production') {
// const vConsole = new VConsole();
// }

const params = parseQuery(window.location.search || '')
console.log('main params -->', params)
const { corpId, agentId = '', from, appId = '' } = params
store.commit('app/setGloblaQuery', {
  corpId,
  agentId,
  appId,
  ...(from ? { from } : {})
})
beforeLoadTaskServices.init()

new Vue({
  store,
  router,
  i18n,
  beforeCreate() {
    const isWhileList = getWhilePath()
    if ((!corpId || (!agentId && !appId)) && !isWhileList) {
      Dialog.alert({
        title: 'error',
        message: 'Not found corpId | agentId.',
        confirmButtonColor: '#000'
      })
      return
    }
  },
  mounted() {
    const watermarkDiv = document.createElement('div')
    const container = document.body
    container.insertBefore(watermarkDiv, container.firstChild)
  },
  render: h => h(App)
}).$mount('#app')

