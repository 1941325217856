export default {
  试驾客户信息: '试驾客户信息',
  报告预览: '报告预览',
  发送报告: '发送报告',
  姓名: '姓名',
  电话: '电话',
  身份证: '身份证',
  销售顾问: '销售顾问',
  试驾类型: '试驾类型',
  试驾项目: '试驾项目',
  试驾车型: '试驾车型',
  试驾车辆: '试驾车辆',
  礼物领取方式: '礼物领取方式',
  试驾预约时间: '试驾预约时间',
  实际试驾时间: '实际试驾时间',
  驾驶证: '驾驶证',
  查看照片: '查看照片',
  试驾协议: '试驾协议',
  查看协议: '查看协议',
  个人信息声明: '个人信息声明',
  试驾录音: '试驾录音',
  试驾数据记录: '试驾数据记录',
  试驾行驶数据: '试驾行驶数据',
  平均时速: '平均时速',
  最高时速: '最高时速',
  急加速: '急加速',
  'Travel Assist启动': 'Travel Assist启动',
  车辆转向: '车辆转向',
  行驶时长: '行驶时长',
  行驶功耗: '行驶功耗',
  'One-App控车': 'One-App控车',
  APP查看车辆状态: 'APP查看车辆状态',
  APP查看用车报告: 'APP查看用车报告',
  APP控制车辆空调: 'APP控制车辆空调',
  APP寻找车辆: 'APP寻找车辆',
  车联网体检: '车联网体检',
  SDS车辆控制: 'SDS车辆控制',
  SDS导航使用: 'SDS导航使用',
  在线音乐播放: '在线音乐播放',
  avatar商店: 'avatar商店',
  APP使用记录: 'APP使用记录',
  智能泊车: '智能泊车',
  IPA泊车: 'IPA泊车',
  远程泊车: '远程泊车',
  车辆充电: '车辆充电',
  充电体验: '充电体验',
  短信: '短信',
  微信: '微信',
  未上传驾照: '未上传驾照',
  未上传协议: '未上传协议',
  试驾报告: '试驾报告',
  '您好！感谢体验': '您好！感谢体验',
  '，已为您生成专属试驾报告，回顾智驾体验！':
		'，已为您生成专属试驾报告，回顾智驾体验！',
  发送成功: '发送成功',
  试驾GPS轨迹图: '试驾GPS轨迹图',
  客户未授权个人信息: '客户未授权个人信息',
  暂无轨迹数据: '暂无轨迹数据',
  预约人姓名: '预约人姓名',
  预约人电话: '预约人电话',
  试驾人姓名: '试驾人姓名',
  试驾人电话: '试驾人电话',
  试驾证件号: '试驾证件号',
  试驾接待人: '试驾接待人',
  'ID. 与众试驾报告': 'ID. 与众试驾报告',
  '尊敬的客户，感谢体验ID. 与众，已为您生成专属试驾报告，点击开启您的试驾回顾之旅！': '尊敬的客户，感谢体验ID. 与众，已为您生成专属试驾报告，点击开启您的试驾回顾之旅！',
}
