export default {
  '列表为已入门店库的可用特殊车辆（不含退役展车），若在库车辆未在列表展示，可在配置单明细查看该车是否被已有配置单占用；配置单过期失效或手动取消，可释放车辆资源': '列表为已入门店库的可用特殊车辆（不含退役展车），若在库车辆未在列表展示，可在配置单明细查看该车是否被已有配置单占用；配置单过期失效或手动取消，可释放车辆资源',
  '查看配置单明细': '查看配置单明细',
  '查询中心仓库存': '查询中心仓库存',
  '创建配置单后，会根据OTD资源分配规则为您预留车辆，可能不是您查看到的VIN号': '创建配置单后，会根据OTD资源分配规则为您预留车辆，可能不是您查看到的VIN号',
  '继续创建配置单': '继续创建配置单',
  '请先选择条件，再查询是否有库存': '请先选择条件，再查询是否有库存',
  '查询条件': '查询条件',
  '车辆节点': '车辆节点',
}
