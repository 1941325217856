export default {
  '线下下单': 'Offline ordering',
  '待开发': 'Pending development',
  '审核内容': 'Review content',
  '申请人': 'Applicant',
  '申请转入门店': 'Apply Transfer Store',
  '申请说明': 'Apply Remark',
  '线索转入': 'Clue transfer',
  '订单修改': 'Order Edit',
  '驳回原因': 'Reason for rejection',
  '不超过200字': 'No more than 200 words',
}
