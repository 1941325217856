const appName = '库存查询'
const routerPath = 'stock'

const routes = [
  {
    path: '/stock',
    name: 'stock',
    component: () => import('@/modules/stock/index.vue'),
    meta: {
      title: '库存查询',
      appName,
      isAppIndex: true,
      routerPath
    }
  }
]

export default routes
