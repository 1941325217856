import Vue from 'vue'
// import moment from 'moment'
import dayjs from 'dayjs'
import store from '@/store'
import { dateFormat } from '@/utils'

Vue.filter('timeFormat', (date, formatStr = 'YYYY-MM-DD') => {
  return date ? dayjs(date).format(formatStr) : date
})
// 跨年
Vue.filter('datetimeText', (data) => {
  if (!data) return '--'
  const year = data.split('-')[0]
  const theYear = new Date().getFullYear()
  if (year != theYear) {
    return dateFormat(data, 'YYYY-MM-DD HH:mm')
  } else {
    return dateFormat(data, 'MM-DD HH:mm')
  }
})

// 通过code 根据字典获取枚举值
Vue.filter('codeFormat', (status) => {
  if (status == null || status === undefined) return ''
  const { dictHash } = store.getters
  if (status && Array.isArray(status)) {
    const dict = status[1]
    if (!dict) return ''
    if (dictHash && dictHash[dict] && Array.isArray(dictHash[dict])) {
      const obj = dictHash[dict].find(({ code }) => code === status[0])
      if (obj) {
        return obj.name
      }
    }
    if (status[0]) {
      return ''
    } else {
      return '--'
    }
  }
  status = status.trim()
  if (typeof (status) === 'string' && status.split(',').length > 1) {
    var str = []
    var arr = status.split(',')
    arr.map((item) => {
      var dict = String(item).substring(0, 4)
      if (dictHash && dictHash[dict] && Array.isArray(dictHash[dict])) {
        str.push(dictHash[dict].find(({ code }) => code === item)?.name)
      }
    })
    if (status) {
      return str.join(',') || '未知'
    } else {
      return ''
    }
  } else {
    var dict = ['BE_CONTRACT', 'BE_DELIVERY_BALANCE_BE_PAY', 'BE_DELIVERY_BALANCE_PAID', 'BE_LOCK', 'CONTRACTED', 'DEPOSITE_BE_PAID', 'IN_REFUNDING', 'ORDER_CANCELED', 'ORDER_CLOSED', 'ORDER_COMPLETED', 'ORDER_LOCKED', 'REFUNDED', 'FORCE_RETREAT_CAR', 'CANCEL_PROCESSING'].includes(status) ? 3000 : String(status).substring(0, 4)
    if (dictHash && dictHash[dict] && Array.isArray(dictHash[dict])) {
      const obj = dictHash[dict].find(({ code }) => code === status)
      if (obj) {
        return obj.name
      }
    }
    if (status) {
      return ''
    } else {
      return '--'
    }
  }
})

// 获取保险字典信息
Vue.filter('insuranceCodeFormat', (status) => {
  if (status == null || status === undefined || !status) return ''
  const { dictHash } = store.getters
  return dictHash[4002].find(({ code }) => { return status === code })?.name
})

