const state = {
  userInfo: { },
}

const mutations = {
  setUserInfo(state, info) {
    state.userInfo = info
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
