export default {
  '无效审核': 'Invalid review',
  '请输入客户姓名/电话/顾问': 'Enter customer name/phone/advisor',
  '共': 'Total',
  '条数据': 'data',
  '全选': 'Select All',
  '批量审核': 'Batch review',
  '待审核': 'To be reviewed',
  '已审核': 'Reviewed',
  '请选择待审核线索': 'Please select leads to be reviewed',
  '请选择同类型角色跟进的线索': 'Please select leads followed by the same role',
  '审核成功': 'Review successful',
  '申请时间': 'Application time',
  '审核时间': 'Review time',
  '申请时间：': 'Application time：',
  '审核截止时间：': 'Review deadline：',
  '逾期': 'Overdue',
  '审核信息：': 'Review information：',
  '审核结果': 'Review result',
  '请选择审核结果': 'Please select review result',
  '审核备注': 'Review note',
  '请输入审核备注': 'Please enter review note',
  '顾问选择': 'Advisor selection',
  '请选择顾问': 'Please select advisor'
}