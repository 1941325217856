export default {
  '审核信息:': '审核信息:',
  '申请时间：': '申请时间：',
  '审核截止时间:': '审核截止时间:',
  '逾期': '逾期',
  '审核结果': '审核结果',
  '请选择审核结果': '请选择审核结果',
  '审核备注': '审核备注',
  '请输入审核备注': '请输入审核备注',
  '顾问选择': '顾问选择',
  '请选择顾问': '请选择顾问',
  '战败审核': '战败审核',
  '请输入客户姓名/电话/顾问': '请输入客户姓名/电话/顾问',
  '共': '共',
  '条数据': '条数据',
  '全选': '全选',
  '审核': '审核',
  '待审核': '待审核',
  '已审核': '已审核',
  '审核成功': '审核成功',
  '请选择待审核线索': '请选择待审核线索',
  '请选择相同类型角色申请的线索': '请选择相同类型角色申请的线索',
  '申请时间': '申请时间',
  '审核时间': '审核时间',
  '来源渠道': '来源渠道',
  '战败原因': '战败原因',
}