/*
 * @Author: 张超越
 * @Date: 2023-07-31 17:29:10
 * @Last Modified by: 张超越
 * @Last Modified time: 2023-09-05 11:25:33
 */

<template>
  <div class="CommonSelect">
    <div v-for="(tag, index) of getItems"
      :key="tag.uuid"
      :class="['tag', selected.includes(tag.value) ? 'active': '', tag.disabled ? 'disabled' : '']"
      :title="tag.title || tag.name"
      @click="handleClick(index, tag)"
      >
      {{ tag.name }}
    </div>
  </div>
</template>

<script>
// Tools
import { isArray, isFunction } from 'lodash'
export default {
  name: 'CommonSelect',
  components: {},
  props: {
    items: {
      // 选项
      type: Array,
      default: () => []
    },
    defaultValue: {
      // 默认值
      type: [Array, Number, String],
      default: () => []
    },
    multiple: {
      // 是否多选
      type: Boolean,
      default: false
    },
    nullable: {
      // 是否可为空
      type: Boolean,
      default: false
    },
    max: {
      // 最大可选数量
      type: Number,
      default: 0
    },
    min: {
      // 最小可选数量
      type: Number,
      default: 0
    },
    valueType: {
      // 值类型，可选值为value和object，如果是object，则返回的是选中项的对象（从items中寻找）
      type: String,
      default: 'value'
    }
  },
  data() {
    return {
      selected: []
    }
  },
  computed: {
    getItems() {
      return this.items
    }
  },
  watch: {
    defaultValue: {
      handler() {
        console.info('select defaultValue change', this.defaultValue)
        this.init()
      },
      deep: true
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      if (!this.multiple) {
        if (isArray(this.defaultValue) === false) {
          this.selected = [this.defaultValue]
        } else {
          console.info('multiple is false, but defaultValue is array, so use defaultValue[0], defaultValue length is', this.defaultValue?.length)
          this.selected = [this.defaultValue[0]]
        }
      } else {
        this.selected = isArray(this.defaultValue) ? this.defaultValue : [this.defaultValue]
      }
    },
    handleClick(index, tag) {
      console.info('handleClick', index, tag)
      const { value, disabled, onClick } = tag

      // 判断是否有自定义点击事件
      if (isFunction(onClick)) {
        onClick(tag)
      }

      // 判断是否禁用
      if (disabled) {
        console.info('禁用')
        return
      }

      // 单选
      if (!this.multiple) {
        this.selected = !this.nullable ? [value] : this.selected.includes(value) ? [] : [value]
        this.emitChange()
        return
      }

      // 多选
      const i = this.selected.indexOf(value)
      if (i > -1) {
        // 判断是否小于最小可选数量
        if (this.min && this.selected.length <= this.min) {
          console.info('小于最小可选数量')
          this.$toast(`最少需要选择${this.min}项`)
          return
        }

        // 判断是否允许为空
        if (!this.nullable && this.selected.length === 1) {
          console.info('不允许为空')
          return
        }

        this.selected.splice(i, 1)
      } else {
        // 判断是否超过最大可选数量
        if (this.max > 0 && this.selected.length >= this.max) {
          console.info('超过最大可选数量')
          this.$toast(`最多可选${this.max}项`)
          return
        }
        this.selected.push(value)
      }
      this.emitChange()
    },
    emitChange() {
      // 处理单选多选
      let value = this.multiple ? this.selected.filter(i => i !== null && i !== undefined) || [] : this.selected[0] ?? ''

      // 判断值类型
      if (this.valueType === 'object') {
        value = this.multiple ? this.selected.map(i => this.items.find(j => j.value == i)).filter(i => i) : this.items.find(i => i.value === value)
      }

      this.$emit('change', value)
    }
  }
}
</script>

<style lang="less" scoped>
.CommonSelect {
  margin-top: -8px;
  display: flex;
  flex-wrap: wrap;
  .tag {
    border-radius: 4px;
    // border: 1px solid #CFCFCF;
    background-color: #e7e7e7;
    box-sizing: border-box;
    color: #323232;
    text-align: center;
    // width: 30%;
    margin-top: 8px;
    margin-right: 2%;
    font-size: 12px;
    height: 27px;
    line-height: 27px;
    padding: 0 12px;
    cursor: pointer;

    // 超出文本省略号
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:last-child {
      margin-right: 0;
    }

    // // 3和3的倍数右侧外距为0
    // &:nth-child(3n) {
    //   margin-right: 0;
    // }

    // // 前3个上外距为0
    // &:nth-child(-n + 3) {
    //   margin-top: 0;
    // }

    &.active, &:active {
      // border-color: #165dff;
      // color: #165dff;
      background-color: #eed484;
    }

    &.disabled {
      // border-color: #CFCFCF;
      color: #CFCFCF;
    }
  }
}
</style>

