const appName = '目标进度'
const routerPath = 'goalProgress'
const routes = [
  {
    path: '/goal-progress',
    name: 'GoalProgress',
    component: () => import('@/modules/goal-progress/Index'),
    meta: {
      title: '目标进度',
      appName,
      isAppIndex: true,
      routerPath
    }
  }
]

export default routes
