import i18nFun from '../router_i18n'
const appName = i18nFun['SOP任务']
const routerPath = 'salesAssistant'

const routes = [
  {
    path: '/sop',
    name: 'SopTask',
    component: () => import('@/modules/sop/index'),
    meta: {
      title: 'SOP任务',
      appName,
      isAppIndex: true,
      routerPath
    }
  },
]

export default routes
