export default {
  大众金融: 'VWFS',
  '下单人信息': 'Order placer information',
  '查看下单人信息': 'View order placer information',
  '姓名': 'Name',
  '电话': 'Phone',
  '车主信息': 'Vehicle owner information',
  '证件类型': 'Id type',
  '证件号码': 'Id number',
  '上牌城市': 'License plate city',
  '企业信息': 'Enterprise information',
  '企业名称': 'Enterprise name',
  '统一社会信用代码': 'Unified social credit code',
  '联系人': 'Contact person',
  '贴息金额': 'Subsidy amount',
  '金融机构': 'Financial institution',
  '金融单号': 'Financial order number',
  '申请时间': 'Application time',
  '订单状态': 'Order status',
  '产品类型': 'Product type',
  '产品名称': 'Product name',
  '银行授权': 'Bank authorization',
  '合同签署': 'Contract signing',
  '首付金额': 'Down payment amount',
  '贷款金额': 'Loan amount',
  '贷款期限': 'Loan term',
  '月供': 'Monthly payment',
  '总利息': 'Total interest',
  '提交金融单撤销申请': 'Submit financial order revocation application',
  '确认撤销当前订单的金融申请': 'Confirm to revoke the financial application of the current order',
  '请确认订单信息是否准确，金融订单将在购车人确认后进行撤销 ？':
    'Please confirm whether the order information is accurate. the financial order will be revoked after the buyer confirms?',
  '请求成功': 'Request successful',
  '请求失败，请联系管理员': 'Request failed, please contact the administrator',
  '折扣金额': 'Discount amount',
  '订单来源': 'Order source',
  '客户类型': 'Customer type',
  '订单总金额': 'Total order amount',
  '意向金': 'Intent money',
  '定金': 'Deposit',
  '尾款': 'Balance',
  '实际金额': 'Current amount',
  '优惠券折扣明细': 'Coupon discount details',
  '折扣备注说明': 'Discount remarks',
  '无折扣原因！': 'No discount reason!',
  '订单修改申请：': 'Order modification application：',
  '销售顾问：': 'Sales advisor：',
  '交付专员：': 'Delivery advisor：',
  '倒计时0小时': 'Countdown 0 hours',
  '倒计时': 'Countdown',
  '小时': 'Hours',
  '大客户': 'Vip customer',
  '查看订单详情': 'View order details',
  '车身颜色：': 'Exterior color：',
  '内饰颜色：': 'Interior color：',
  '选装：': 'Optional features：',
  '预计交付时间：': 'Expected delivery time：',
  '销售门店：': 'Sales store：',
  '交付门店：': 'Delivery store：',
  '订单状态：': 'Order status：',
  '开发中。。。': 'Under development...',
  '返回': 'Back',
  '请选择分配人员！': 'Please select an assigned person!',
  '更多': 'More',
  '填写跟进': 'Fill in follow-up',
  '推送线上合同': 'Push online contracts',
  '取消': 'Cancel',
  '这是第一条选装信息': 'This is the first optional information',
  '这是第二条选装信，可以换行展示更多的数据':
    'This is the second optional information, which can be displayed in multiple lines to show more data',
  '订单详情': 'Order details',
  '订单轨迹': 'Order trajectory',
  '查看修改申请': 'View modification request',
  '订单取消修改': 'Cancel order modification',
  '订单重新编辑': 'Re-edit order',
  '申请修改订单': 'Request to modify order',
  '订单分配': 'Order allocation',
  '预览合同': 'Preview contract',
  '购车合同查看': 'View purchase contract',
  '添加客户微信': 'Add customer wechat',
  '绑定客户微信': 'Bind customer wechat',
  '金融详情': 'Financial details',
  '下订': 'Order',
  '定金支付': 'Deposit payment',
  '锁单': 'Lock order',
  '合同推送': 'Contract push',
  '订单完成': 'Order completed',
  '确认推送合同至客户签署 ？': 'Confirm pushing the contract to the customer for signing?',
  '推送合同成功': 'Contract pushed successfully',
  '推送合同失败，请联系管理员': 'Failed to push the contract, please contact the administrator',
  '分配成功': 'Allocation successful',
  '提示': 'Prompt',
  '取消修改后，系统会删除修改的内容，保持原订单不变。确认取消 ？':
    'After canceling the modification, the system will delete the modified content and keep the original order unchanged. confirm cancellation?',
  '操作成功！': 'Operation successful!',
  '请选择一个外部联系人进行绑定': 'Please select an external contact for binding',
  '转交付备注': 'To deliver remark',
  '销售门店': 'Sales store',
  '驳回': 'Reject',
  '修改前：': 'Before modification：',
  '车系车型': 'Vehicle series and model',
  '该选装无物料信息！': 'This optional feature has no material information!',
  '配置信息': 'Configuration information',
  '请选择车系车型': 'Please select a vehicle series and model',
  '外饰': 'Exterior color',
  '请选择外饰颜色': 'Please select an exterior color',
  '内饰': 'Interior color',
  '请选择内饰颜色': 'Please select an interior color',
  '选装': 'Optional features',
  '请选择选装': 'Please select optional features',
  '购车类型': 'Purchase type',
  '购车方式': 'Purchase method',
  '请输入姓名': 'Please enter a name',
  '手机号码': 'Mobile phone number',
  '请输入手机号码': 'Please enter a mobile phone number',
  '请选择证件类型': 'Please select an id type',
  '请输入证件号码': 'Please enter an id number',
  '请输入企业名称': 'Please enter an enterprise name',
  '请输入联系人姓名': 'Please enter the contact person\'s name',
  '手机号': 'Mobile phone number',
  '请输入手机号': 'Please enter a mobile phone number',
  '请输入统一社会信用代码': 'Please enter the unified social credit code',
  '交付门店': 'Delivery store',
  '请选择交付门店': 'Please select a delivery store',
  '优惠抵扣': 'Discount',
  '修改前金额': 'Amount before modification',
  '车辆总价': 'Total vehicle price',
  '购车总价': 'Total purchase price',
  '保存': 'Save',
  '提交审核': 'Submit for review',
  '取消修改': 'Cancel modification',
  '重新编辑': 'Edit again',
  '一键催办': 'Urgent reminder',
  '通过': 'Approved',
  '确认驳回': 'Confirm rejection',
  '确定': 'Confirm',
  '驳回原因': 'Rejection reason',
  '请填写驳回原因': 'Please enter a rejection reason',
  '个人': 'Individual',
  '企业': 'Enterprise',
  '分期': 'Installment',
  '全款': 'Full payment',
  '识别中...': 'Recognizing...',
  '身份证': 'Id card',
  '请选择正确的车系车型': 'Please select the correct vehicle model',
  '确认提交审核 ？': 'Confirm submission for review?',
  '催办成功！': 'Urgent reminder successful!',
  '确认审批通过 ？': 'Confirm approval?',
  '确认': 'Confirm',
  '立即驳回': 'Reject immediately',
  '订单编号：': 'Order number：',
  '请输入订单编号/申请人': 'Please enter order number/applicant',
  '共': 'Total ',
  '条数据': ' data',
  '申请人：': 'Applicant：',
  '申请时间：': 'Application time：',
  '全选': 'Select all',
  '订单修改审核': 'Order modification review',
  '批量审核': 'Batch review',
  '审核结果': 'Review result',
  '请至少选择一条订单！': 'Please select at least one order!',
  '请输入不少于10个字的跟进记录': 'Please enter a follow-up record with at least 10 characters',
  '请输入不少于10个字的跟进内容': 'Please enter a follow-up record with at least 10 characters',
  '跟进信息': 'Follow-up information',
  '提交': 'Submit',
  '分配': 'Assign',
  '全部': 'All',
  '已下定': 'Order placed',
  '已锁单': 'Order locked',
  '已签合同': 'Contract signed',
  '已交车': 'Vehicle delivered',
  '20小时（含）以内': 'Within 20 hours (inclusive)',
  '20-24小时': '20-24 hours',
  '24小时后': 'After 24 hours',
  '下单时间': 'Order time',
  '订单车型': 'Order model',
  '付款方式': 'Payment method',
  '金融状态': 'Finance status',
  '审核中': 'Under review',
  '已取消': 'Cancelled',
  '销售顾问': 'Sales advisor',
  '请选择待分配的订单': 'Please select orders to be assigned',
  '购车信息': 'Car purchase information',
  '确认取消？': 'Confirm cancel?',
  '请上传不超过30MB的图片': 'Please upload images up to 30MB in size',
  '上传线下合同': 'Upload offline contract'
}
