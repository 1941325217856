const state = {
}
const mutations = {
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
