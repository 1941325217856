export default {
  立即试驾: '立即试驾',
  '新建排程': '新建排程',
  '当场试驾': '当场试驾',
  '跳转成功': '跳转成功',
  '今': '今',
  '常用功能': '常用功能',
  '无法跳转': '无法跳转',
  '功能开发中 敬请期待！': '功能开发中 敬请期待！',
  '业绩排行': '业绩排行',
  '暂无数据!': '暂无数据!',
  '今日': '今日',
  '本周': '本周',
  '本月': '本月',
  '试驾量': '试驾量',
  '订单量': '订单量',
  '交付量': '交付量',
  '销售顾问': '销售顾问',
  '销售组': '销售组',
  '组': '组',
  '工作概览': '工作概览',
  '员工': '员工',
  '全部': '全部',
  '渠道：': '渠道：',
  '有效线索量': '有效线索量',
  '确认': '确认',
  '取消': '取消',
  '待办事项': '待办事项',
  '一': '一',
  '二': '二',
  '三': '三',
  '四': '四',
  '五': '五',
  '六': '六',
  '日': '日',
  '请输入应用名称': '请输入应用名称',
  '全部应用': '全部应用',
  '点击加号可添加应用至常用功能(至多添加4个)': '点击加号可添加应用至常用功能(至多添加4个)',
  '销售工具': '销售工具',
  '企微运营': '企微运营',
  '门店管理': '门店管理',
  '培训学习': '培训学习',
  '至多添加4应用至常用功能': '至多添加4应用至常用功能',
  '操作成功': '操作成功',
  '操作失败': '操作失败',
  '试驾用户': '试驾用户',
  '手机号': '手机号',
  '请输入': '请输入',
  '试驾类型': '试驾类型',
  '试驾车型': '试驾车型',
  '暂无可用车型': '暂无可用车型',
  '暂无可用车辆': '暂无可用车辆',
  '试驾时间': '试驾时间',
  '预约车系': '预约车系',
  '线索验证中': '线索验证中',
  '提示': '提示',
  '该线索还未分配跟进人，是否领取 ？': '该线索还未分配跟进人，是否领取 ？',
  '店内公共池已存在同号码线索，不允许重复创建，是否直接领取？': '店内公共池已存在同号码线索，不允许重复创建，是否直接领取？',
  '我': '我',
  '请联系master 或者 店长将线索分配到自己。': '请联系master 或者 店长将线索分配到自己。',
  '请选择日期': '请选择日期',
  '暂无可选活动': '暂无可选活动',
  '请选择试驾时间': '请选择试驾时间',
  '请选择试驾车型及试驾车辆': '请选择试驾车型及试驾车辆',
  '请选择试驾车型': '请选择试驾车型'
}
