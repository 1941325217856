import i18nFun from '../router_i18n'
// const appName = i18nFun.opportunityManagement
const routerPath = 'approveManage'

const routes = [
  {
    path: '/approve-manage-factory',
    name: 'ApproveManageFactory',
    component: () => import('@/modules/approveManage/index'),
    meta: {
      title: '审批中心',
      isAppIndex: true,
      routerPath: 'approveManageFactory',
    },
  },
  {
    path: '/approveManage',
    name: 'ApproveManage',
    component: () => import('@/modules/approveManage/index'),
    meta: {
      title: '审批中心',
      isAppIndex: true,
      routerPath,
    },
  },
  {
    path: '/clue-reassignment-detail',
    name: 'ClueReassignmentDetail',
    component: () =>
      import('@/modules/approveManage/clueReassignment/detail.vue'),
    meta: {
      title: '改派线索详情',
      routerPath,
    },
  },
  {
    path: '/clue-transfer-detail',
    name: 'clueTransferDetail',
    component: () =>
      import('@/modules/approveManage/leadChangeInto/detail.vue'),
    meta: {
      title: '线索转入详情',
      routerPath,
    },
  },
]

export default routes
