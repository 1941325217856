export default {
  '请选择交车时间': 'Please select a delivery time',
  '购方税号': '购方税号',
  '购方名称': '购方名称',
  '产地': '产地',
  '发动机号码': '发动机号码',
  '合格证号': '合格证号',
  '车辆类型': '车辆类型',
  '厂牌型号': '厂牌型号',
  '销售价格': '销售价格',
  '车辆识别代号': '车辆识别代号',
  '车辆信息': '车辆信息',
  '车身颜色：': '车身颜色：',
  '内饰颜色：': '内饰颜色：',
  '发起预约': '发起预约',
  '确认书查看': '确认书查看',
  '确认书签署': '确认书签署',
  '暂无数据!': '暂无数据!',
  '线下签署': '线下签署',
  '线上签署': '线上签署',
  'PDI信息查看': 'PDI信息查看',
  '查看PDI记录': '查看PDI记录',
  '已上传': '已上传',
  '交车记录上传': '交车记录上传',
  '礼品记录上传': '礼品记录上传',
  '信息登记': '信息登记',
  '信息更新': '信息更新',
  '执行确认': '执行确认',
  '认证确认': '认证确认',
  '当前客户未签署交车确认函，请在客户签署成功后，再进行贷后资料上传':
    '当前客户未签署交车确认函，请在客户签署成功后，再进行贷后资料上传',
  '交车确认书签署': '交车确认书签署',
  '发送合同成功': '发送合同成功',
  '请确认是否完成该任务 ？': '请确认是否完成该任务 ？',
  '操作成功': '操作成功',
  '操作失败': '操作失败',
  '交车时间：': '交车时间：',
  '预计交车时间：': '预计交车时间：',
  '客户信息：': '客户信息：',
  '车款支付：': '车款支付：',
  '发票状态：': '发票状态：',
  '保险状态：': '保险状态：',
  '交付专员：': '交付专员：',
  '交车确认前置任务检测': '交车确认前置任务检测',
  '检查未通过，请先完成前置任务': '检查未通过，请先完成前置任务',
  '车辆发票': '车辆发票',
  '交车仪式记录': '交车仪式记录',
  '车辆SIM卡认证': '车辆SIM卡认证',
  '车辆激活与绑定': '车辆激活与绑定',
  '预览交车确认书': '预览交车确认书',
  '分配时间': '分配时间',
  '查看订单详情': '查看订单详情',
  '选装：': '选装：',
  '特别关注': '特别关注',
  '车款支付': '车款支付',
  '发票状态': '发票状态',
  '查看发票': '查看发票',
  '上传发票': '上传发票',
  '保险状态': '保险状态',
  '金融状态': '金融状态',
  '取消': '取消',
  '上传': '上传',
  '请上传正确图片文件(支持jpg/jpeg/png)': '请上传正确图片文件(支持jpg/jpeg/png)',
  '上传中...': '上传中...',
  '上传失败': '上传失败',
  '请上传发票图片': '请上传发票图片',
  '上传成功': '上传成功',
  '返回': '返回',
  '提交': '提交',
  '资料上传': '资料上传',
  '交车确认函': '交车确认函',
  '请完成上传': '请完成上传',
  '请输入不少于10个字的跟进记录': '请输入不少于10个字的跟进记录',
  '跟进信息': '跟进信息',
  '请选择联系结果': '请选择联系结果',
  '暂无法进行交车排程！': '暂无法进行交车排程！',
  '品牌保险推荐': '品牌保险推荐',
  '请选择品牌保险推荐': '请选择品牌保险推荐',
  '跟进记录': '跟进记录',
  '下次跟进时间': '下次跟进时间',
  '请选择下次跟进时间': '请选择下次跟进时间',
  '确定': '确定',
  '请选择时间': '请选择时间',
  '请确认，结束时间要大于开始时间': '请确认，结束时间要大于开始时间',
  '操作成功！': '操作成功！',
  '请选择分配人员': '请选择分配人员',
  '到店提车': '到店提车',
  '更多': '更多',
  '确认分配': '确认分配',
  '临时授权': '临时授权',
  '授权人员': '授权人员',
  '请选择授权人员': '请选择授权人员',
  '授权期限': '授权期限',
  '请选择授权期限': '请选择授权期限',
  '提车时间变更提醒': '提车时间变更提醒',
  '重新排程': '重新排程',
  '客户提车时间已超期，在变更排程前，请确认客户是否已到店': '客户提车时间已超期，在变更排程前，请确认客户是否已到店',
  '客户未到店': '客户未到店',
  '客户到店': '客户到店',
  '跟进轨迹': '跟进轨迹',
  '客户临时授权': '客户临时授权',
  '请选择交付专员！': '请选择交付专员！',
  '请选择': '请选择',
  '交车礼品配图': '交车礼品配图',
  '礼品数量说明': '礼品数量说明',
  '请输入礼品数量说明': '请输入礼品数量说明',
  '请完成必填项填写': '请完成必填项填写',
  '客户手机号后4位/车辆VIN码/订单号/客户姓名全称': '客户手机号后4位/车辆VIN码/订单号/客户姓名全称',
  '共': '共',
  '条数据': '条数据',
  '全选': '全选',
  '全部': '全部',
  '已整备': '已整备',
  '已PDI': '已PDI',
  '已入库': '已入库',
  '已发运': '已发运',
  '已交车': '已交车',
  '重新分配': '重新分配',
  '待分配': '待分配',
  '车系车型': '车系车型',
  '预计交付时间': '预计交付时间',
  '实际交车时间': '实际交车时间',
  '付款方式': '付款方式',
  '全款': '全款',
  '分期': '分期',
  'PDI状态': 'PDI状态',
  '交付单与车辆状态': '交付单与车辆状态',
  '未申请': '未申请',
  '已申请': '已申请',
  '已开具': '已开具',
  '自购': '自购',
  '未出单': '未出单',
  '已出单': '已出单',
  '交付专员': '交付专员',
  '请选择待分配的交车单': '请选择待分配的交车单',
  '发票': '发票',
  '重新上传发票': '重新上传发票',
  '机动车登记证': '机动车登记证',
  '牌照办理确认': '牌照办理确认',
  '代为办理': '代为办理',
  '自行办理': '自行办理',
  '牌照号码登记': '牌照号码登记',
  '车辆行驶证上传': '车辆行驶证上传',
  '请上传正确图片文件(支持jpg/png/gif)': '请上传正确图片文件(支持jpg/png/gif)',
  '请选择牌照办理': '请选择牌照办理',
  '请输入牌照号码': '请输入牌照号码',
  '交车时间': '交车时间',
  '车辆准备清单': '车辆准备清单',
  '是否风险': '是否风险',
  '交付方式': '交付方式',
  '到店交付': '到店交付',
  '异地交付': '异地交付',
  '已生产': '已生产',
  '未排产': '未排产',
  '已锁单': '已锁单',
  '已大定': '已大定',
  '休眠': '休眠',
  '待办状态': '待办状态',
  '车辆交付提醒': '车辆交付提醒',
  '交付单关联车辆已经与': '交付单关联车辆已经与',
  '客户完成交付。': '客户完成交付。',
  '请确认是否已经完成交付动作': '请确认是否已经完成交付动作',
  '确认完成交付': '确认完成交付',
  '请上传发票后，再确认完成交付': '请上传发票后，再确认完成交付',
  '下单时间': '下单时间',
  '大客户': '大客户',
  '购车合同已同步': '购车合同已同步',
  '查看合同详情': '查看合同详情',
  '本订单无线上合同副本，请手动拍照合同（含补充单）进行上传': '本订单无线上合同副本，请手动拍照合同（含补充单）进行上传',
  '已排产': '已排产',
  '已下线': '已下线',
  '预约到店验车': '预约到店验车',
  '异常订单处理': '异常订单处理',
  '更多协议签署': '更多协议签署',
  '请选择上传': '请选择上传',
  '预约验车时间': '预约验车时间',
  '取消看车': '取消看车',
  '客户已到店': '客户已到店',
  '修改时间': '修改时间',
  '跟进填写': '跟进填写',
  '确认到店': '确认到店',
  '请上传车辆状态告知书后，再确认完成交付': '请上传车辆状态告知书后，再确认完成交付',
  '发起签署': '发起签署',
  '查看记录': '查看记录',
  '记录更新': '记录更新',
}
