import Vue from 'vue'
import store from '@/store'
import beforeLoadTaskServices from '@/services/beforeLoadTaskServices'
import { parseQuery } from '@/utils'
const { search } = window.location
let { corpId, agentId, appId = '' } = parseQuery(search || '')
Vue.mixin({
  methods: {
    /**
     * 
     * @param {*} code 字典code
     * @param {*} filterCode 过滤code
     * @param {string} callType 返回类型 object\name\array
     * @returns 
     */
    getDictNameByCode(code, filterCode = '', callType = 'name') {
      const dictHash = store.getters.dictHash

      if (!code || !dictHash) return ''
      const list = dictHash[code]
      if (list && list.length) {
        switch (callType) {
          case 'object':
            return list.find(item => item.code == filterCode)[0] || {}
          case 'name':
            return list.find(item => item.code === filterCode)?.name || '--'
          case 'array':
            return list
          default:
            return '--'
        }
      }
    },

    /**
     * 跟上面方法唯一不同的是，不会返回'--',而是返回空字符串
     * @param {*} code 
     * @param {*} filterCode 
     * @param {*} callType 
     * @returns 
     */
    getNameByDictcode(code, filterCode = '', callType = 'name') {
      const dictHash = store.getters.dictHash

      if (!code || !dictHash) return ''
      const list = dictHash[code]
      if (list && list.length) {
        switch (callType) {
          case 'object':
            return list.find(item => item.code == filterCode)[0] || {}
          case 'name':
            return list.find(item => item.code === filterCode)?.name || ''
          case 'array':
            return list
          default:
            return ''
        }
      }
    },
    formatRMB(number) {
      if (number == '' || number == null || isNaN(Number(number))) return ''
      number = Number(number).toFixed(2)
      const parts = number.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return parts.join('.')
    },
    // 重新获取detail详情
    async getDetailAgain() {
      const params = { corpId, agentId }
      await beforeLoadTaskServices.loadEmployeeDetail(null,params)
    },
    // 获取隐私号配置
    async getHasMappingAgain(dealerId) {
      await beforeLoadTaskServices.getHasMapping(null, { dealerId })
    },
  }
})

