import request from '@/utils/request'
import store from '@/store'
import jwtDecode from 'jwt-decode'
import { v4 as uuidv4 } from 'uuid'
import sha1 from 'js-sha1'
import { Toast } from 'vant'

// jsApiList 为接口列表 https://work.weixin.qq.com/api/doc/90000/90136/90507
const jsApiList = ['chooseImage', 'selectExternalContact', 'openUserProfile', 'selectEnterpriseContact',
  'getCurExternalContact', 'startRecord', 'stopRecord', 'onVoiceRecordEnd', 'translateVoice', 'getLocation', 'openLocation', 'scanQRCode',
  'launchMiniprogram', 'chooseVideo', 'previewFile', 'onHistoryBack', 'navigateToAddCustomer', 'setClipboardData',
  'openEnterpriseChat', 'createCorpGroupChat', 'openExistedChatWithMsg', 'getContext', 'shareAppMessage', 'sendChatMessage', 'shareToExternalMoments', 'shareToExternalContact', 'shareWechatMessage', 'shareToExternalChat',
  'onMenuShareAppMessage', 'showAllNonBaseMenuItem', 'onMenuShareWechat', 'onMenuShareTimeline', 'updateEnterpriseChat', 'getCurExternalChat'
]

const vendorServices = {
  /**
   * wx.config
   */
  async wxAgentConfig(success, fail) {
    let { corpId, agentId, appId } = store.getters.globalQuery
    const getTicketUrl = '/qw-service/api/v1/employee/ticket'
    const result = await request.get(getTicketUrl, {
      params: {
        agentId: appId || agentId
      }
    })
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg)
      return
    }
    const { ticket, agentIdTicket } = data
    const nonceStr = uuidv4().replace(/-/g, '')
    const timestamp = new Date().getTime()
    const signature = sha1(`jsapi_ticket=${ticket}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${window.location.href.split('#')[0]}`)
    const agentSignature = sha1(`jsapi_ticket=${agentIdTicket}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${window.location.href.split('#')[0]}`)
    window.wx.config({
      beta: true,
      debug: false,
      appId: corpId,
      timestamp,
      nonceStr,
      signature,
      jsApiList
    })
    window.wx.ready(() => {
      window.wx.agentConfig({
        corpid: corpId,
        agentid: appId || agentId,
        timestamp,
        nonceStr,
        signature: agentSignature,
        jsApiList,
        success: success ? (res => { success(res); console.log('wx.agentConfig successIn', res) })
          : (res => { console.info('wx.agentConfig success', res) }),
        fail: fail ? (res => fail(res))
          : ((err) => { console.error('wx.agentConfig fail', err) })
      })
    })
    window.wx.error(err => {
      console.error('wx.config err', err)
    })
  },
  /**
   * 根据联系人uuid查信息
   */
  async getExternalInfoUserId({ uuid }) {
    const { dealerId } = jwtDecode(store.getters.token)
    const result = await request.get('/qw-organization-service/v1.1/customer/getCustomerByExternalUserid', {
      params: {
        dealerId,
        externalUserId: uuid
      }
    })
    const { code, description, data = {} } = result
    if (code !== '000000') {
      throw new Error(description || '操作失败')
    }
    return data
  },
  /**
   * 身份证识别
   */
  async idCardTextRecognition({ url }) {
    const result = await request.post('/ims-integrate/integrate/tcloud/idCard', {
      imgUrl: url
    })
    const { code, msg, data } = result
    if (code !== '000000') {
      throw new Error(msg)
    }
    return data
  },
  /**
   * 购车发票识别
   */
  async carInvoiceTextRecognition({ url }) {
    const result = await request.post('/ims-integrate/integrate/tcloud/carInvoice', {
      imgUrl: url
    })
    const { code, msg, data } = result
    if (code !== '000000') {
      throw new Error(msg)
    }
    return data
  },
  /**
   * 营业执照识别
   */
  async businessLicenseRecognition({ url }) {
    const result = await request.post('/ims-integrate/integrate/tcloud/bizLicense', {
      imgUrl: url
    })
    const { code, msg, data } = result
    if (code !== '000000') {
      throw new Error(msg)
    }
    return data
  },
  /**
   * 行驶证识别
  */
  async licensePlateNumber({ url }) {
    const result = await request.post('/ims-integrate/integrate/tcloud/licensePlateNumber', {
      imgUrl: url
    })
    const { code, msg, data } = result
    if (code !== '000000') {
      throw new Error(msg)
    }
    return data
  }
}

export default vendorServices
