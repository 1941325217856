const routerPath = 'orderManage'
import i18nFun from '../router_i18n'
const appName = i18nFun.订单明细
const routes = [
  {
    path: '/order-list',
    name: 'orderList',
    component: () => import('@/modules/order/list'),
    meta: {
      title: i18nFun.订单明细,
      appName,
      isAppIndex: true,
      routerPath,
    },
  },
  {
    path: '/order-detail',
    name: 'orderDetail',
    component: () => import('@/modules/order/detail'),
    meta: {
      title: i18nFun.订单详情,
      appName,
      routerPath,
    },
  },
  {
    path: '/order-follow',
    name: 'orderFollow',
    component: () => import('@/modules/order/follow'),
    meta: {
      title: i18nFun.订单跟进,
      appName,
      routerPath,
    },
  },
  {
    path: '/order-contract',
    name: 'orderContract',
    component: () => import('@/modules/order/contract'),
    meta: {
      title: i18nFun.购车合同,
      appName,
      routerPath,
    },
  },
  {
    path: '/order-edit',
    name: 'orderEdit',
    component: () => import('@/modules/order/edit'),
    meta: {
      title: i18nFun.订单修改,
      appName,
      routerPath,
    },
  },
  {
    path: '/order-edit-examine',
    name: 'orderEditExamine',
    component: () => import('@/modules/order/editExamine'),
    meta: {
      title: i18nFun.订单修改审核,
      appName,
      routerPath,
    },
  },
  {
    path: '/order-deliver-remark',
    name: 'orderDeliverRemark',
    component: () => import('@/modules/order/deliverRemark'),
    meta: {
      title: i18nFun.转交付备注,
      appName,
    },
  },
  {
    path: '/order-noteSpecial',
    name: 'orderNoteSpecial',
    component: () => import('@/modules/order/noteSpecial'),
    meta: {
      title: i18nFun['备注特殊资源'],
      appName,
    },
  },
]

export default routes
