export default {
  '车型':'Model',
  '车系':'Series',
  '车辆状态恢复后，客户可进行预约试驾操作，请确认是否恢复！':'After the vehicle status is restored, customers can make an appointment for a test drive. Please confirm if it has been restored!',
  '恢复可用成功':'Successfully restored availability',
  '恢复可用':'Restore available',
  '可用车辆': 'Available vehicles',
  '不可用车辆': 'Unavailable vehicles',
  '查看排程': 'View Schedule',
  '外饰颜色：': 'Exterior Color',
  '内饰颜色：': 'Interior Color',
  '录入日期：': 'Entry Date',
  '车辆占用': 'Vehicle Occupation ',
  '车牌': 'Vehicle number',
  '预约时间': 'Appointment time',
  '预约详细信息': 'Appointment details',
  '占用原因': 'Occupy the reason',
  '请选择占用原因': 'Please select occupy the reason',
  '占用时间': 'Occupy date',
  '请选择占用时间': 'Please select occupy date',
  '占用说明': 'Occupy description',
  '请输入占用说明': 'Please enter occupy description',
  '返回': 'Back',
  '提交': 'Submit',
  '至': 'To',
  '请确认，结束时间要大于开始时间': 'Please confirm that the end time is greater than the start time',
  '报错了': 'Error occurred',
  '车牌：': 'Vehicle number：',
  '试驾车占用轨迹': 'Test drive occupy trail',
  '月': 'Month',
  '试驾车占用': 'Test drive occupy',
}
