export default {
  '您的试驾体验专属瞬间': 'Your exclusive moment of test drive experience',
  '恭喜您荣膺': 'Congratulations on Achieving',
  '本次试驾获取的': 'Achievements from This Test Drive',
  '积分': 'Integral',
  '成长值': 'Growth Points',
  '点击了解定级规则': 'Click to Learn the Top Rules',
  '您的驾驶表现': 'Your Dynamic Performance',
  '试驾信息回顾': 'Test Drive Information Recap',
  '试驾报告试驾车型': 'Vehicle Model',
  '试驾路程': 'Test Drive Route',
  '试驾时长': 'Test Drive Duration',
  '试驾报告最高车速': 'Maximum Speed',
  '与传统燃油车相比，这次试驾行驶预计可为您节省费用': 'Compared with traditional fuel vehicle, the test drive mileage can save you cost',
  '元': 'yuan',
  '驾驶ID. UNYX，让节能环保成为您的日常选择': ' Driving ID. UNYX , let energy saving and environmental protection become your daily choice',
  '试驾门店': 'Test Drive Store',
  '您的专属服务顾问': 'Your ID. UNYX Expert',
  '85%的用户还会想了解': '85% of Users Also Want to Explore',
  '试驾有礼': 'Enjoy Exclusive Test Drive Rewards',
  '尚未深度体验': 'Not yet experienced',
  '定级规则': 'Top Rules',
  '扫描二维码并预约试驾': 'Scan the QR code and book a test drive',
  '领取精美试驾礼品': ' Receive a beautiful test drive gift',
  '试驾报告领取试驾礼品': 'Claim Your Exclusive Gift',
  '礼物已领取': 'Gift received',
  '播放演示视频': 'play demo',
  '长按海报可保存至本地哦': 'Long press the poster to save it locally',
  '海报图片生成中，请耐心等候!': 'Resignation inheritance poster image is being generated, please be patient!',
  '未中奖': 'Not winning'
}