import i18nFun from '../router_i18n'
const appName = i18nFun['试驾报告']
const routerPath = 'testDriveReport'

const routes = [
  {
    path: '/test-drive-report',
    name: 'testDriveReport',
    // component: () => import('@/modules/testDriveReport/newReport'),
    // component: () => import('@/modules/testDriveReport/index'),
    component: () => import('@/modules/testDriveReport/indexV3'),
    // component: () => import('@/modules/testDriveReport/indexV2'),
    meta: {
      routerPath,
    },
    beforeEnter(to, from, next) {
      to.meta.appName = appName
      const { lang } = to.query
      if (lang == 'en_US') to.meta.title = 'Test drive report'
      else to.meta.title = i18nFun['试驾报告']
      next()
    },
  },
  {
    path: '/test-drive-record',
    name: 'testDriveRecord',
    component: () => import('@/modules/testDrive/viewRecord.vue'),
    meta: {
      title: i18nFun.testDriveRecord,
      appName
    }
  },
  {
    path: '/testDriveGift',
    name: 'TestDriveGift',
    component: () => import('@/modules/testDrive/testDriveGift.vue'),
    meta: {
      title: i18nFun['试驾礼品'],
      appName,
      isAppIndex: true,
      routerPath
    },
  },
  {
    path: '/receiveGift',
    name: 'ReceiveGift',
    component: () => import('@/modules/testDrive/receiveGift.vue'),
    meta: {
      title: i18nFun['领取试驾礼'],
      appName,
      isAppIndex: true,
      routerPath
    },
  }
]

export default routes
