const appName = '特殊车配置单'
const routerPath = 'specialCarOrderConfig'
const routes = [
  {
    path: '/special-car-order-config',
    name: 'specialCarOrderConfig',
    component: () => import('@/modules/specialCarOrderConfig/index'),
    meta: {
      title: '特殊车配置单',
      appName,
      isAppIndex: true,
      routerPath
    }
  }
]

export default routes
