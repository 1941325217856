import store from '@/store'
import { Toast } from 'vant'
import SERVER from './server'
import { thenResolve } from '@/utils/index'
import request from '@/utils/request'
const beforeLoadTaskServices = {
  /**
   * 获取通过 iframe 引入当前应用时候，会下发 token
   */
  getParentToken: null,
  init() {
    const { from } = store.getters.globalQuery
    if (from === 'notify') {
      this.parentTokenInit()
    }
  },
  parentTokenInit() {
    this.getParentToken = new Promise(resolve => {
      window.addEventListener('message', function (event) {
        const { TOKEN } = event.data
        if (TOKEN) {
          store.commit('app/setToken', TOKEN)
          resolve()
        }
      })
    })
  },
  /**
   * 通过微信授权回调地址登录
   */
  async login(requestUtil, { code, agentId, state }) {
    return new Promise((resolve, reject) => {
      requestUtil.get(`${SERVER.QW_SERVICE}/api/v1/employee/token?code=${code}&agentId=${agentId}&state=${state}`).then(loginRes => {
        const { code: resCode, msg, data } = loginRes
        if (resCode !== 0) {
          const message = msg || '登录发生错误'
          // Toast.fail(message)
          throw new Error(message)
        }
        if (data.token) {
          store.commit('app/setToken', data.token)
        }
        resolve(data)
      }).catch(reject)
    })
  },
  /**
   * 加载员工信息
   */
  async loadEmployeeDetail(requestUtil, { agentId }) {
    const requestAxios = requestUtil ? requestUtil : request
    const result = await requestAxios.get(`${SERVER.ADMIN_SERVICE}/api/v1/employee/detail`, { params: { agentId } })
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '加载员工信息发生错误')
      return
    }
    // 存储用户信息 包含了角色、店铺等
    store.commit('user/setUserInfo', data)
  },
  /**
   * 加载字典信息
   */
  async loadDict(requestUtil) {
    const result = await requestUtil.get(`${SERVER.BASE_DATA_SERVICE}/api/v1/dict/list`, {
      sysUserCompanyRoleReqVO: true
    })
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '登录发生错误')
      return
    }
    store.commit('app/setDict', data)
    // 获取cos配置信息
    const cosConfig = data[9000]?.length && JSON.parse(data[9000][0]?.remark)
    store.commit('app/setCosConfig', cosConfig)
    // localStorage.setItem('cosConfig', JSON.stringify(cosConfig))
  },
  /**
   * 查询试驾等级
  */
  async getTestDriveConf(requestUtil) {
    const result = await requestUtil.post(`${SERVER.BASE_DATA_SERVICE}/api/v1/testDriveConf/list`, { pageNum: 1, pageSize: 999 })
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg)
      return
    }
    let dictHashObj = store.getters.dictHash
    dictHashObj[1012] = data.map(({ code, level, id, levelEn }) => ({ code, level, id, levelEn, name: level }))
    store.commit('app/setDict', dictHashObj)
  },
  /**
   * 获取企微资源
   */
  async getQwResource(requestUtil, { agentId }) {
    return new Promise((resolve, reject) => {
      requestUtil.get(`${SERVER.ADMIN_SERVICE}/api/v1/resource/qwResource?agentId=${agentId}`).then(loginRes => {
        const { code: resCode, msg, data } = loginRes
        if (resCode !== 0) {
          const message = msg || '加载企微资源发生错误'
          Toast.fail(message)
          throw new Error(message)
        }
        const menus = { menu: data, btn: {} }
        data.map(item => {
          if (item.resourcesType === 3) {
            menus.btn[item.code] = item
          }
        })
        store.commit('app/setMenu', JSON.stringify(menus))
        resolve(data)
      }).catch(reject)
    })
  },
  /**
   * 查询门店是否开通隐私号功能
   */
  async getHasMapping(requestUtil, { dealerId }) {
    const requestAxios = requestUtil ? requestUtil : request
    const result = await requestAxios.get(`${SERVER.ADMIN_SERVICE}/api/v1/dealer/has/unicom/mapping?dealerId=${dealerId}`).then(thenResolve)
    store.commit('app/setHasMapping', result || false)
  }
}

export default beforeLoadTaskServices
