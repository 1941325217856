
const routerPath = 'deliverRemind'
import i18nFun from '../router_i18n'
const appName = i18nFun.交付提醒
const routes = [
  {
    path: '/deliverRemind-list',
    name: 'DeliverRemindList',
    component: () => import('@/modules/deliverRemind/list.vue'),
    meta: {
      title: i18nFun.交付提醒,
      appName,
      isAppIndex: true,
      routerPath,
    },
  },
  {
    path: '/remoteDeliveryFee',
    name: 'RemoteDeliveryFee',
    component: () => import('@/modules/deliverRemind/remoteDeliveryFee.vue'),
    meta: {
      appName,
      routerPath,
    },
  },
]

export default routes
