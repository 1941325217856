/*
 * @Author: juan.liu
 * @LastEditors: juan.liu
 * @Date: 2023-08-16 14:25:18
 * @LastEditTime: 2023-08-18 16:25:48
 * @FilePath: /ims-qw-h5/src/store/getters.js
 */
import { v4 as uuidv4 } from 'uuid'

const getters = {
  // app
  token: state => state.app.token,
  globalQuery: state => state.app.globalQuery,
  dict: state => state.app.dict,
  dictHash: state => state.app.dictHash,
  shops: state => state.app.shops,
  templateClueInfo: state => state.app.templateClueInfo,
  enumeration: state => state.app.enumeration,
  selectedShopId: state => state.app.selectedShopId,
  /**
   * @function 获取指定code的枚举
   */
  getDictHash: state => dictType => {
    return state.app.dictHash ? ((dictType in state.app.dictHash
      ? state.app.dictHash[dictType]
      : []
    ) .map(item => ({
      id: uuidv4(),
      label: item.name,
      value: item.code,
      ...item
    }))
    ) : []
  },

  userInfo: state => state.user.userInfo,
  menu: state => state.app.menu,
  hasMapping: state => state.app.hasMapping,
  cosConfig: state => state.app.cosConfig,
}

export default getters
