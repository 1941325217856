const appName = '线索转入'
const routerPath = 'leadChangeInto'

const routes = [
  {
    path: '/leadChangeInto',
    name: 'LeadChangeInto',
    component: () => import('@/modules/leadChangeInto/index'),
    meta: {
      title: '线索转入',
      appName,
      isAppIndex: true,
      routerPath
    }
  },
  {
    path: '/changeInto',
    name: 'ChangeInto',
    component: () => import('@/modules/leadChangeInto/changeInto'),
    meta: {
      title: '线索转入',
      appName,
      isAppIndex: true,
      routerPath
    }
  },
]

export default routes
