const appName = '试驾车管理'
const routerPath = 'carManage'
const routes = [
  {
    path: '/car-manage',
    name: 'carManage',
    component: () => import('@/modules/carManage/index'),
    meta: {
      title: '试驾车管理',
      appName,
      isAppIndex: true,
      routerPath
    }
  },
  {
    path: '/car-manage/operate',
    name: 'carOperate',
    component: () => import('@/modules/carManage/operate.vue'),
    meta: {
      title: '车辆处理',
      appName,
      isAppIndex: true,
      routerPath
    }
  },
  {
    path: '/car-manage/scheduling',
    name: 'carScheduling',
    component: () => import('@/modules/carManage/scheduling.vue'),
    meta: {
      title: '试驾车排程',
      appName,
      isAppIndex: true,
      routerPath
    }
  },
  {
    path: '/car-manage/detail',
    name: 'carManageDetail',
    component: () => import('@/modules/carManage/detail.vue'),
    meta: {
      title: '试驾车详情',
      appName,
      isAppIndex: true,
      routerPath
    },
  },
]

export default routes
