<template>
   <div class="water-marker-container"></div>
</template>
<script>
export default {
  computed:{
    getUserInfo() {
      return this.$store.getters.userInfo.name
    },
  },
  watch:{
    getUserInfo:{
      handler(val, oldVal){
        if (val !== oldVal) this.createWaterMark(val)
      },
      deep:true,
      immediate: true
    }
  },
  methods:{
    createWaterMark(userName){
      // 获取 viewport 宽度和高度
      var viewportWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      var viewportHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

      // 获取页面总高度（包括滚动区域）
      var pageHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight)

      // 计算水印在横向和纵向需要重复的次数
      var repeatX = Math.ceil(viewportWidth / 250)
      var repeatY = Math.ceil(pageHeight / 250)

      // 创建水印元素
      for (var i = 0; i < repeatX; i++) {
        for (var j = 0; j < repeatY; j++) {
          var watermark = document.createElement('div')
          watermark.innerText = userName
          watermark.className = 'watermark'
          watermark.style.top = j * 250 + 'px'
          watermark.style.left = i * 250 + 'px'
          document.getElementsByClassName('water-marker-container')[0]?.appendChild(watermark)
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.water-marker-container{
    position:absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    pointer-events: none;
    
}

</style>