import i18nFun from '../router_i18n'
const appName = i18nFun.publicClues
const routerPath = 'clueDistribution'
const routes = [
  {
    path: '/clue-distribution',
    name: 'Distribution',
    component: () => import('@/modules/clueDistribution/index'),
    meta: {
      title: i18nFun.publicClues,
      appName,
      isAppIndex: true,
      routerPath
    }
  },
  {
    path: '/distribution-detail',
    name: 'DistributionDetail',
    component: () => import('@/modules/clueDistribution/detail'),
    meta: {
      title: i18nFun.clueDetails,
      appName,
      isAppIndex: true,
      routerPath
    }
  },
]

export default routes
