export default {
  'views__details': {
    '无效审核': '无效审核'
  },
  '无效审核': '无效审核',
  '请输入客户姓名/电话/顾问': '请输入客户姓名/电话/顾问',
  '共': '共',
  '条数据': '条数据',
  '全选': '全选',
  '批量审核': '批量审核',
  '待审核': '待审核',
  '已审核': '已审核',
  '请选择待审核线索': '请选择待审核线索',
  '请选择同类型角色跟进的线索': '请选择同类型角色跟进的线索',
  '审核成功': '审核成功',
  '申请时间': '申请时间',
  '审核时间': '审核时间',
  '申请时间：': '申请时间：',
  '审核截止时间：': '审核截止时间：',
  '逾期': '逾期',
  '审核信息：': '审核信息：',
  '审核结果': '审核结果',
  '请选择审核结果': '请选择审核结果',
  '审核备注': '审核备注',
  '请输入审核备注': '请输入审核备注',
  '顾问选择': '顾问选择',
  '请选择顾问': '请选择顾问'
}