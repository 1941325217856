const language = window.navigator.language.toLocaleLowerCase().includes('en')
  ? 'en'
  : 'zh'
// const language = 'en'
const json = {
  zh: {
    交付助手: '交付助手',
    // 订单明细
    订单明细: '订单明细',
    订单详情: '订单详情',
    订单跟进: '订单跟进',
    购车合同: '购车合同',
    订单修改: '订单修改',
    订单修改审核: '订单修改审核',
    转交付备注: '转交付备注',
    // 交车明细
    交车明细: '交车明细',
    交车详情: '交车详情',
    PDI上报: 'PDI上报',
    PDI记录: 'PDI记录',
    交车跟进: '交车跟进',
    交车排程: '交车排程',
    资料上传: '资料上传',
    交车记录: '交车记录',
    礼品记录: '礼品记录',
    金融贷后材料: '金融贷后材料',
    开票信息: '开票信息',
    上牌指标登记: '上牌指标登记',
    临牌信息登记: '临牌信息登记',
    牌照信息登记: '牌照信息登记',
    品牌保险意愿确认: '品牌保险意愿确认',
    发票详情: '发票详情',
    车辆准备清单: '车辆准备清单',
    // 线索明细
    clueDowngradingManagement: '线索降级管理',
    defeatWarningReminder: '战败预警提醒',
    clueManagement: '线索明细',
    createNewClue: '新建线索',
    followUp: '线索跟进',
    clueDetails: '线索详情',
    details: '详情信息',
    clueEditing: '线索编辑',
    improveCustomerInformation: '完善客户信息',
    instoreClueInquiry: '店内线索查询',
    // 公共线索
    publicClues: '公共线索池',
    // 商机管理
    opportunityManagement: '商机明细',
    销售助手: '销售助手',
    工作台: '工作台',
    新建试驾排程: '新建试驾排程',
    我的待办: '我的待办',
    建群任务: '建群任务',
    待办任务: '待办任务',
    预约明细: '预约明细',
    预约试驾: '预约试驾',
    预约详情: '预约详情',
    试驾车排程: '试驾车排程',
    预约到店: '预约到店',
    试驾报告: '试驾报告',
    备注特殊资源: '备注特殊资源',
    预约到店验车: '预约到店验车',
    更多协议签署: '协议签署',
    交付提醒: '交付提醒',
    试驾车点检: '试驾车点检',
    车辆点检: '车辆点检',
    点检记录: '点检记录',
    素材库: '素材库',
    离职继承: '离职继承',
    试驾礼品: '试驾礼品',
    试驾礼抽奖: '试驾礼抽奖',
    品牌保险推荐: '品牌保险推荐',
    送车上门审批: '送车上门审批',
    异常流程审批: '异常流程审批',
    充电桩白名单: '充电桩白名单',
    提车礼品报缺: '提车礼品报缺',
    合并线索: '合并线索',
    '试乘试驾信息登记': '试乘试驾信息登记',
    送车上门申请: '送车上门申请',
    领取试驾礼: '领取试驾礼',
    交付确认书签署: '交付确认书签署',
    排程量: '排程量',
    试驾量: '试驾量',
    智能巡检: '智能巡检',
    申请退单: '申请退单',
    低满工单: '低满工单',
    线下下单: '线下下单',
    'uploadContract': '上传线下合同',
    'viewContract': '线下合同',
  },
  en: {
    交付助手: 'Delivery Assistant',
    // 订单明细
    订单明细: 'Order Details',
    订单详情: 'Order Details',
    订单跟进: 'Order Follow-up',
    购车合同: 'Vehicle Purchase Contract',
    订单修改: 'Order Amendment',
    订单修改审核: 'Order Amendment Review',
    转交付备注: 'Delivery Handover Remarks',
    // 交车明细
    交车明细: 'Delivery Details',
    交车详情: 'Delivery Details',
    PDI上报: 'PDI Reporting',
    PDI记录: 'PDI Records',
    交车跟进: 'Delivery Follow-up',
    交车排程: 'Delivery Scheduling',
    资料上传: 'Document Upload',
    交车记录: 'Delivery Records',
    礼品记录: 'Gift Records',
    金融贷后材料: 'Post-Loan Financial Materials',
    开票信息: 'Invoice Information',
    上牌指标登记: 'Registration Quota Registration',
    临牌信息登记: 'Temporary License Plate Registration',
    牌照信息登记: 'License Plate Registration',
    品牌保险意愿确认: 'Brand Insurance Intent Confirmation',
    发票详情: 'Invoice Details',
    车辆准备清单: 'Vehicle Preparation Checklist',
    // 线索明细
    'clueDowngradingManagement': 'Lead Downgrading Management',
    'defeatWarningReminder': 'Defeat warning reminder',
    'clueManagement': 'Lead management',
    'createNewClue': 'Create lead',
    'followUp': 'Follow-up',
    'clueDetails': 'Lead details',
    'details': 'Details',
    'clueEditing': 'Lead Editing',
    'improveCustomerInformation': 'Improve customer information',
    'instoreClueInquiry': 'Instore lead inquiry',
    // 公共线索
    publicClues: 'Public Lead Pool',
    // 商机管理
    opportunityManagement: 'Opportunity management',
    销售助手: 'Sales Assistant',
    工作台: 'Workspace',
    新建试驾排程: 'New test drive schedule',
    我的待办: 'My To Do',
    建群任务: 'Group building task',
    待办任务: 'To-Do Tasks',
    预约明细: 'Appointment detail',
    预约试驾: 'Book a test drive',
    预约详情: 'Appointment detail',
    试驾车排程: 'Test drive schedule',
    预约到店: 'Appointment to store',
    试驾报告: 'Test drive report',
    备注特殊资源: 'Note special resources',
    预约到店验车: 'Vehicle inspection in store',
    更多协议签署: 'Protocol sign',
    交付提醒: 'Delivery reminder',
    试驾车点检: 'Test drive vehicle inspection',
    车辆点检: 'Vehicle inspection',
    点检记录: 'Inspection records',
    素材库: 'Material',
    离职继承: 'Resignation inheritance',
    试驾礼品: 'Test drive gift',
    试驾礼抽奖: 'Test drive gift lottery',
    品牌保险推荐: 'Brand insurance recommend',
    送车上门审批: 'Delivery approval',
    异常流程审批: 'Approval of abnormal process',
    充电桩白名单: 'Charge whitelist',
    提车礼品报缺: 'Car pickup gift report missing',
    领取试驾礼: 'Pick up the test drive',
    合并线索: 'Merge Clues',
    试乘试驾信息登记: 'Test drive information registration',
    送车上门申请: 'Delivery application',
    交付确认书签署: 'Delivery confirmation',
    排程量: 'Scheduling',
    试驾量: 'Test drive',
    智能巡检: 'Intelligent inspection',
    申请退单: 'Apply for a refund',
    低满工单: 'Low Full Work Order',
    线下下单: 'Offline ordering',
    'uploadContract': 'Upload Contract',
    'viewContract': 'Offline contract',
  },
}
const i18nFun = json[language]
export default i18nFun
