import { parseQuery } from '@/utils'
import store from '@/store'
let { agentId } = parseQuery(window.location.search || '')
// 按钮权限
export default {
  // 被绑定元素插入父节点时调用
  inserted(el, binding, vnode) {
    if (!permissionJudge(binding.value) && binding.value) {
      el?.parentNode?.removeChild(el)
    }
    function permissionJudge(value) {
      try {
        const globalQuery = store.getters.globalQuery
        agentId = globalQuery.agentId || parseQuery(window.location.search || '')['agentId']
        let menus = []
        if (sessionStorage.getItem('menu')) {
          menus = JSON.parse(sessionStorage.getItem('menu'))
        }
        const { btn = {} } = menus || {}
        if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            if (btn[`${agentId}-${value[i]}`]) {
              return true
            }
          }
          return false
        } else {
          return !!btn[`${agentId}-${value}`]
        }
      } catch (error) {
        return false
      }
    }
  }
}
