import i18nFun from '../router_i18n'
const appName = i18nFun['交付助手']
const routerPath = 'deliveryAssistant'

const routes = [
  {
    path: '/delivery-assistant/home',
    name: 'deliveryAssistantHome',
    component: () => import('@/modules/delivery-assistant/index'),
    meta: {
      title: i18nFun['交付助手'],
      appName,
      isAppIndex: true,
      routerPath,
    },
  },
  {
    path: '/delivery-assistant/list',
    name: 'deliveryAssistantList',
    component: () => import('@/modules/delivery-assistant/index'),
    meta: {
      title: i18nFun['交付助手'],
      appName,
      isAppIndex: true,
      routerPath: 'deliverManage',
    },
  },
  {
    path: '/delivery-assistant/app',
    name: 'deliveryAssistantApp',
    component: () => import('@/modules/delivery-assistant/index'),
    meta: {
      title: i18nFun['交付助手'],
      appName,
      isAppIndex: true,
      routerPath,
    },
  },
  {
    path: '/remoteDeliverApprove',
    name: 'remoteDeliverApprove',
    component: () => import('@/modules/delivery-assistant/RemoteDeliverApprove.vue'),
    meta: {
      title: i18nFun['送车上门审批'],
      appName,
      routerPath: 'deliverApprove',
    },
  },
  {
    path: '/abnormalProcessApprove',
    name: 'abnormalProcessApprove',
    component: () => import('@/modules/abnormalProcessApprove/index.vue'),
    meta: {
      title: i18nFun['异常流程审批'],
      appName,
      routerPath: 'processApprove',
    },
  },
  {
    path: '/abnormalProcessApprove/details',
    name: 'abnormalProcessApproveDetails',
    component: () => import('@/modules/abnormalProcessApprove/details.vue'),
    meta: {
      title: i18nFun['异常流程审批'],
      appName,
    },
  }
]

export default routes
