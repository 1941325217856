export default {
  立即试驾: 'Test drive',
  '新建排程': 'New schedule',
  '当场试驾': 'Walk-in TD',
  '跳转成功': 'Jump successfully',
  '今': 'Today',
  '常用功能': 'Common Tools',
  '无法跳转': 'Unable to Jump',
  '功能开发中 敬请期待！': 'Function under development, please look forward to It!',
  '业绩排行': 'Ranking',
  '暂无数据!': 'No data available!',
  '今日': 'Today',
  '本周': 'Week',
  '本月': 'Month',
  '试驾量': 'Test drive',
  '订单量': 'Order',
  '交付量': 'Delivery',
  '销售顾问': 'Advisor',
  '销售组': 'Sales Team',
  '组': 'Group',
  '工作概览': 'Work Overview',
  '员工': 'Employee',
  '全部': 'All',
  '渠道': 'Channel',
  '线索量': 'Lead',
  '确认': 'Confirm',
  '取消': 'Cancel',
  '待办事项': 'To-Do List',
  '一': 'One',
  '二': 'Two',
  '三': 'Three',
  '四': 'Four',
  '五': 'Five',
  '六': 'Six',
  '日': 'Day',
  '请输入应用名称': 'Please enter application name',
  '全部应用': 'All Applications',
  '点击加号可添加应用至常用功能(至多添加4个)': 'Click plus to add apps to common functions (up to 4)',
  '销售工具': 'Sales Tools',
  '企微运营': 'Enterprise WeChat Operation',
  '门店管理': 'Store Management',
  '培训学习': 'Training and Learning',
  '至多添加4应用至常用功能': 'Add up to 4 applications to common functions',
  '操作成功': 'Operation successful',
  '操作失败': 'Operation failed',
  '试驾用户': 'Test drive user',
  '手机号': 'Phone number',
  '请输入': 'Please enter',
  '试驾类型': 'Test drive type',
  '试驾车型': 'Test drive vehicle model',
  '暂无可用车型': 'No available vehicle models',
  '暂无可用车辆': 'No available vehicles',
  '试驾时间': 'Test drive time',
  '预约车系': 'Appointment vehicle series',
  '线索验证中': 'Lead verification in progress',
  '提示': 'Prompt',
  '该线索还未分配跟进人，是否领取 ？': 'The lead has not been assigned to a follow-up person. Would you like to claim it?',
  '店内公共池已存在同号码线索，不允许重复创建，是否直接领取？': 'There is already a lead with the same number in the store\'s public pool. Do you want to claim it directly?',
  '我': 'Myself',
  '请联系master 或者 店长将线索分配到自己。': 'Please contact the master or store manager to assign leads to yourself.',
  '请选择日期': 'Please select a date',
  '暂无可选活动': 'There are currently no optional activities available',
  '请选择试驾时间': 'Please select a test drive time',
  '请选择试驾车型及试驾车辆': 'Please select the test drive model and test drive vehicle',
  '请选择试驾车型': 'Please select a test drive model',
  '试驾场景': 'Test drive scenario'
}