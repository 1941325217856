const appName = '特殊车库存'
const routerPath = 'specialCar'

const routes = [
  {
    path: '/special-car-stock',
    name: 'specialCarStock',
    component: () => import('@/modules/specialCarStock/index.vue'),
    meta: {
      title: '特殊车库存',
      appName,
      isAppIndex: true,
      routerPath
    }
  },
  {
    path: '/special-car-stock/operate',
    name: 'specialCarOperate',
    component: () => import('@/modules/specialCarStock/operate.vue'),
    meta: {
      title: '查询中心仓库存',
      appName,
      isAppIndex: true,
      routerPath
    }
  },
  {
    path: '/special-car-stock/detail',
    name: 'specialCarDetail',
    component: () => import('@/modules/specialCarStock/detail.vue'),
    meta: {
      title: '特殊车配置单',
      appName,
      isAppIndex: true,
      routerPath
    },
  },
]

export default routes
